import React from 'react';

const cartConfig = {
    settings: {

    },
    routes: [
        {
            path: '/shop/cart',
            component: React.lazy(() => import('./Cart'))
        },
    ]
};

export default cartConfig;
