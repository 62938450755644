import React from 'react';
import { Redirect } from 'react-router-dom';
import TitumUtils from '@titum/utils';
import LoginConfig from 'app/main/login/LoginConfig';
import HomeConfig from "../app/main/Home/HomeConfig";
import pagesConfig from "../app/main/pages/pagesConfig";
import productPagesConfig from "../app/main/products/productPagesConfig";
import checkoutConfig from "../app/main/pages/dashboard/components/components/checkout/checkoutConfig";
import verifiedEmailConfig from "../app/main/login/verified/verifiedEmailConfig";

const routeConfigs = [
    HomeConfig,
    LoginConfig,
    verifiedEmailConfig,
    checkoutConfig,
    ...pagesConfig,
    ...productPagesConfig
];

const routes = [
    ...TitumUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: '/',
        component: () => <Redirect to="/dashboard" />,
        exact : true

    }
];

export default routes;
