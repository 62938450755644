import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
});

const InvoiceTable = (props) => {
    const { invoice } = props;

    return (
        invoice && (
            <View style={styles.tableContainer}>
                <InvoiceTableHeader />
                <InvoiceTableRow items={invoice.products} />
                <InvoiceTableBlankSpace rowsCount={1} />
                <InvoiceTableFooter invoice={invoice}/>
            </View>
        )
        )
}

export default InvoiceTable
