import React from 'react';

const orderPayConfig = {
    settings: {

    },
    auth:  ['customer'],
    routes: [
        {
            path: '/checkout/order-pay/:orderReference',
            component: React.lazy(() => import('./OrderPay')),
        },
    ]
};

export default orderPayConfig;
