import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import BillShippAddressService
    from "../../../../services/customerService/billShipAddressService/BillShippAddressService";
import {toast} from "react-toastify";
import {SubmitButton} from "../../../../../_constants/utilities.contants";

function Address( props ) {
    const user = useSelector(({ auth }) => auth.user);
    const {address, data, handleCancel, handleReload} = props;
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        'id' : '', 'first_name' : user.data.user.firstName, 'last_name' : user.data.user.lastName, 'email' : user.data.user.email, 'address' : '', 'province' : '', 'city' : '', 'postcode' : '', 'order_notes' : '', 'company_name' : '', 'phone_no' : user.data.user.phone_1
    });

    useEffect(() => {
        if(address === 'Edit Billing') {
            setFormData(data.billing_address[0])
        }else if(address === 'Edit Shipping') {
            setFormData(data.shipping_address[0])
        }
    },[])


    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        let data = {formData, model : ''}
        if(address === 'Add Billing') {
            data.model = 'Add Billing'
            BillShippAddressService.createOrUpdate(data).then(() => {
                setLoading(false)
                toast.success( "Added" );
                handleReload()
            })
        }else if(address === 'Edit Billing'){
            data.model = 'Edit Billing'
            BillShippAddressService.createOrUpdate(data).then(() => {
                setLoading(false)
                toast.success( "Updated" );
                handleReload()
            })
        }else if(address === 'Add Shipping'){
            data.model = 'Add Shipping'
            BillShippAddressService.createOrUpdate(data).then(() => {
                setLoading(false)
                toast.success( "Added" );
                handleReload()
            })
        }else if (address === 'Edit Shipping') {
            data.model = 'Edit Shipping'
            BillShippAddressService.createOrUpdate(data).then(() => {
                setLoading(false)
                toast.success( "Updated" );
                handleReload()
            })
        }
    }


    return (
        <>
            <form onSubmit={handleSubmit} autoComplete="false">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="checkout-title">{address} Details</h2>
                        <div className="row">
                            <div className="col-sm-6">
                                <label>First Name *</label>
                                <input type="text"
                                       id="first_name"
                                       name="first_name"
                                       className="form-control"
                                       value={formData.first_name}
                                       onChange={e => setFormData({
                                           ...formData,
                                           first_name: e.target.value
                                       })}
                                       required />
                            </div>

                            <div className="col-sm-6">
                                <label>Last Name *</label>
                                <input type="text"
                                       id="last_name"
                                       name="last_name"
                                       className="form-control"
                                       value={formData.last_name}
                                       onChange={e => setFormData({
                                           ...formData,
                                           last_name: e.target.value
                                       })}
                                       required />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <label>Company Name (Optional)</label>
                                <input type="text"
                                       id="company_name"
                                       name="company_name"
                                       className="form-control"
                                       value={formData.company_name}
                                       onChange={e => setFormData({
                                           ...formData,
                                           company_name: e.target.value
                                       })}
                                />
                            </div>

                        </div>

                        <label>Street address *</label>
                        <input type="text"
                               id="address"
                               name="address"
                               className="form-control"
                               placeholder="House number and Street name"
                               value={formData.address}
                               onChange={e => setFormData({
                                   ...formData,
                                   address: e.target.value
                               })}
                               required

                        />

                        <div className="row">
                            <div className="col-sm-6">
                                <label>Town / City *</label>
                                <input type="text"
                                       id="city"
                                       name="city"
                                       className="form-control"
                                       value={formData.city}
                                       onChange={e => setFormData({
                                           ...formData,
                                           city: e.target.value
                                       })}
                                       required
                                />
                            </div>

                            <div className="col-sm-6">
                                <label>Province *</label>
                                <input type="text"
                                       id="province"
                                       name="province"
                                       className="form-control"
                                       value={formData.province}
                                       onChange={e => setFormData({
                                           ...formData,
                                           province: e.target.value
                                       })}
                                       required />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <label>Postcode / ZIP *</label>
                                <input type="text"
                                       id="postcode"
                                       name="postcode"
                                       className="form-control"
                                       value={formData.postcode}
                                       onChange={e => setFormData({
                                           ...formData,
                                           postcode: e.target.value
                                       })}
                                       required
                                />
                            </div>

                            <div className="col-sm-6">
                                <label>Phone Number *</label>
                                <input type="text"
                                       id="phone_no"
                                       name="phone_no"
                                       className="form-control"
                                       value={formData.phone_no}
                                       onChange={e => setFormData({
                                           ...formData,
                                           phone_no: e.target.value
                                       })}
                                       required
                                />
                            </div>
                        </div>

                        <label>Email address *</label>
                        <input type="email"
                               className="form-control"
                               id="email"
                               name="email"
                               value={formData.email}
                               onChange={e => setFormData({
                                   ...formData,
                                   email: e.target.value
                               })}
                               required />

                        <SubmitButton text="SAVE CHANGES" loading={loading}/>

                        <button type="button" className="btn btn-outline-primary-2 ml-3" onClick={handleCancel}>
                            <span>CANCEL</span>
                            <i>&#10006;</i>
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}


export default Address