import productConfig from "./product/productConfig";
import categoryListConfig from "./shopList/category-list/categoryListConfig";
import brandListConfig from "./shopList/brand-list/brandListConfig";
import offeredProductsConfig from "./offered-products/OfferedProductsConfig";
import premierProductsConfig from "./premier-products/PremierProductsConfig";
import brandConfig from "./products-by-brand/BrandConfig";

const productPagesConfig = [
    productConfig,
    offeredProductsConfig,
    premierProductsConfig,
    brandConfig,
    categoryListConfig,
    brandListConfig
];

export default productPagesConfig;
