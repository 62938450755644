import React from 'react';

const privacySecuConfig = {
    settings: {

    },
    routes: [
        {
            path: '/privacy-security',
            component: React.lazy(() => import('./PrivacySecu'))
        },
    ]
};

export default privacySecuConfig;
