import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

class CustomerService {

    createOrUpdate = async (data) => {
        try {
            const response = await axios({
                method: 'post',
                url: `${API_URL}/customer/create-update-ship-bill-address`,
                data : {
                    formData : data.formData,
                    model : data.model,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('weavers_website_access_token'),
                }
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    };

}

export default new CustomerService();