import React from 'react';

const paymentReceiptConfig = {
    settings: {

    },
    auth:  ['customer'],
    routes: [
        {
            path: '/payment/receipt/:orderReferenceNumber',
            component: React.lazy(() => import('./PaymentReceipt'))
        },
    ]
};

export default paymentReceiptConfig;
