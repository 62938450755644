import React from 'react';

const productConfig = {
    settings: {

    },
    routes: [
        {
            path: '/product/:productName/:productSku?',
            component: React.lazy(() => import('./Product')),
        },
    ]
};

export default productConfig;
