import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

class CartListService {

    getCartList = async () => {
        try {
            const response = await axios({
                method: 'get',
                url: `${API_URL}/customer/get-cartlist`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer ' + this.getAccessToken(),
                }
            });
            return response.data;

        } catch (error) {
            throw error;
        }
    };

    addToCartList = async (data) => {
        try {
            await axios({
                method: 'post',
                url: `${API_URL}/customer/add-to-cartlist`,
                data : {
                    'product_id' : data.product_id,
                    'qty' : data.qty,
                    'sum' : data.sum,
                    'size' : data.size,
                    'tot_weight' : data.tot_weight
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer ' + this.getAccessToken(),
                }
            });
        } catch (error) {
            throw error;
        }
    };

    updateCartList = async (data) => {
        try {
            await axios({
                method: 'put',
                url: `${API_URL}/customer/update-cartlist`,
                data : {
                    'product_id' : data.product_id,
                    'qty' : data.qty,
                    'sum' : data.sum,
                    'size' : data.size,
                    'tot_weight' : data.tot_weight
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer ' + this.getAccessToken(),
                }
            });
        } catch (error) {
            throw error;
        }
    };

    addToMultipleCartList = async (cartList) => {
        try {
            await axios({
                method: 'post',
                url: `${API_URL}/customer/add-to-multiple-cartlist`,
                data : {
                    'cartList' : cartList
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer ' + this.getAccessToken(),
                }
            });
        } catch (error) {
            throw error;
        }
    };

    deleteCartList = async (productId) => {
        try {
            const res = await axios({
                method: 'delete',
                url: `${API_URL}/customer/delete-cartlist?product_id=${productId}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer ' + this.getAccessToken(),
                }
            });
            return res.data;
        } catch (error) {
            throw error;
        }
    };

    deleteAllCartList = async (ids) => {
        try {
            const res = await axios({
                method: 'delete',
                url: `${API_URL}/customer/delete-all-cartlist?product_ids=${ids}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer ' + this.getAccessToken(),
                }
            });
            return res.data;
        } catch (error) {
            throw error;
        }
    };

    getAccessToken = () => {
        return window.localStorage.getItem('weavers_website_access_token');
    };

}

export default new CartListService();