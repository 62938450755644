import Dashboard from './Dashboard';

const dConfig = {
    settings: {

    },
    auth:  ['customer'],
    routes: [
        {
            path: '/dashboard',
            component: Dashboard
        }
    ]
};

export default dConfig;
