import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from "@titum/utils/TitumUtils_2";
import { toast } from 'react-toastify';
import storage from 'redux-persist/lib/storage'
import {persistReducer} from "redux-persist";


export const addToCart = (product, qty, size) => async (dispatch, getState) => {
    toast.success( "Item Added to Cart" );
    dispatch(setAddToCard({product, qty, size}));

};

export const removeFromCart = (productId) => async (dispatch, getState) => {
    toast.success( "Item Removed from Cart" );
    dispatch(setRemoveFromCard({productId}));
};

export const changeQty = (productId, qty) => async (dispatch, getState) => {
    dispatch(setChangeQty({productId, qty}));
    //dispatch(setChangeShipping());
};

export const refreshStore = () => async (dispatch, getState) => {
    dispatch(setRefreshStore());
};

export const changeShipping = (shipping) => async (dispatch, getState) => {
    dispatch(setChangeShipping({shipping}));

};

const initialState = {
    cartList: [],
    shipping: 0,
 };

const cartSlice = createSlice({
    name: 'titumEcommerceApp/cart',
    initialState,
    reducers: {
        setAddToCard : {
            reducer: (state, action) => {
                const productId = action.payload.product.id;

                if ( findIndex( state.cartList, product => product.id === productId ) !== -1 ) {
                    const cartList = state.cartList.reduce( ( cartAcc, product ) => {
                        if ( product.id === productId) {
                            cartAcc.push( {
                                ...product,
                                size : action.payload.size ? action.payload.size : '',
                                qty: parseInt( product.qty ) + parseInt(action.payload.qty),
                                sum: ( product.discount ? product.sale_price : product.unit_price ) * ( parseInt( product.qty ) + parseInt(action.payload.qty) ) ,
                                tot_weight : (product.weight)  * ( parseInt( product.qty ) + parseInt(action.payload.qty) )
                            })
                        } else {
                            cartAcc.push(product)
                        }
                        return cartAcc
                    }, [] )

                    return { ...state, cartList }
                }

                return {
                    ...state,
                    cartList: [
                        ...state.cartList,
                        {
                            ...action.payload.product,
                            size : action.payload.size ? action.payload.size : '',
                            qty: action.payload.qty,
                            sum: ( action.payload.product.discount ? action.payload.product.sale_price : action.payload.product.unit_price ) * action.payload.qty,
                            tot_weight: (action.payload.product.weight) * action.payload.qty
                        }
                    ]
                }
            }
        },
        setRemoveFromCard : {
            reducer: (state, action) => {
                return {
                    ...state,
                    cartList: state.cartList.filter( item => item.id !== action.payload.productId )
                };
            }
        },
        setChangeQty : {
            reducer: (state, action) => {
                const cartList = state.cartList.reduce( ( cartAcc, product ) => {
                    if ( product.id === action.payload.productId) {
                        cartAcc.push( { ...product,
                            qty: action.payload.qty,
                            sum: ( product.discount ? product.sale_price : product.unit_price ) * action.payload.qty,
                            tot_weight: (product.weight ) * action.payload.qty,
                        })
                    } else {
                        cartAcc.push( product )
                    }
                    return cartAcc;
                }, [] )
                return { ...state, cartList };
            }
        },
        setRefreshStore : {
            reducer: (state, action) => {
                return { ...state, cartList: [], shipping: "free" };

            }
        },
        setChangeShipping : {
            reducer: (state, action) => {
                return { ...state, shipping: state.shipping + action.payload.shipping };

            }
        },
    }
});

const persistConfig = {
    keyPrefix: "weavers-",
    key: "cartList",
    storage
}

export const { setAddToCard, setRemoveFromCard, setChangeQty, setRefreshStore, setChangeShipping} = cartSlice.actions;

export default persistReducer( persistConfig, cartSlice.reducer );
