import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

class OrderService {

    getAll = async () => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${API_URL}/customer/orders-by-customer`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('weavers_website_access_token'),
                }
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    };

    getByReference = async (reference) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${API_URL}/customer/order-by-reference?reference=${reference}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('weavers_website_access_token'),
                }
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    };

    cancelOrder = async (reference) => {
        try {
            const response = await axios({
                method: 'DELETE',
                url: `${API_URL}/customer/cancel-order?reference=${reference}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('weavers_website_access_token'),
                }
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    };


}

export default new OrderService();