import React from 'react';
import { Link } from 'react-router-dom';
import { mobileMenu } from '@titum/utils/TitumUtils_2';
import {logoutUser} from "../../auth/store/userSlice";
import { useDispatch, useSelector } from 'react-redux';



function MobileMainNav( props ) {
    const dispatch = useDispatch();
    const user = useSelector(({ auth }) => auth.user);

    React.useEffect( () => {
        mobileMenu();
    } )

    return (
        <nav className="mobile-nav">
            <ul className="mobile-menu">
                <li>
                    <Link to={ `/` }>
                        Home
                    </Link>
                </li>

                <li>
                    <Link to={ `${process.env.PUBLIC_URL}/shop/brand` }>
                        Brands
                    </Link>
                </li>

                <li>
                    <Link to={`${process.env.PUBLIC_URL}/shop/premier-products`} className="sf-with-ul">
                        Premier Products
                    </Link>
                </li>

                <li>
                    <Link to={ `${process.env.PUBLIC_URL}/shop/offered-products` }>
                        Offers
                    </Link>

                </li>

                {user.role.length !== 0 ? (
                    <li>
                        <div className="header-dropdown">
                            <Link to="#">{user.data.user.firstName}</Link>
                            <div className="header-menu" style={{ backgroundColor : '#343a40'}}>
                                <ul>
                                    <li><Link to="/dashboard">My account</Link></li>
                                    <li><Link to="#" onClick={() => dispatch(logoutUser())}>Logout</Link></li>
                                </ul>
                            </div>
                        </div>
                    </li>
                ):(
                    <li className="login">
                        <Link to="/login" data-toggle="modal">Sign in / Sign up</Link>
                    </li>
                )}

            </ul>
        </nav>
    );
}

export default MobileMainNav;