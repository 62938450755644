import React from 'react';
import { Helmet } from 'react-helmet';
import OwlCarousel from '../../Titum-layouts/shared-components/OwlCarousel';
import IntroSlider from '../../Titum-layouts/shared-components/IntroSlide';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import Banner from "../../Titum-layouts/shared-components/Banner";
import {introSlider, popularCategories} from '@titum/settings'
import data from '../../mock_data/data.json';
import NewArrivalProducts from "../products/product-collections/NewArrivalProducts";
import Service from "../../Titum-layouts/shared-components/Services";
import {useDispatch} from 'react-redux';
import {setProductCategoryName} from "../products/store/filterSlice";

function Home(props) {
    const dispatch = useDispatch();


    return (
        <>
            <Helmet>
                <title>Weavers Online Store for dresses | Sri Lanka</title>
            </Helmet>
            <h1 className="d-none">Weavers Online Store for dresses | Sri Lanka</h1>

            <div className="main">
                <div className="intro-slider-container">
                    <OwlCarousel adClass="intro-slider owl-simple owl-nav-inside" data-toggle="owl" carouselOptions={ introSlider } >
                        { data.introBanners.map( ( slider, index ) =>
                            <IntroSlider slider={ slider } key={ `introSlider_${index}` } />
                        ) }
                    </OwlCarousel>

                    <span className="slider-loader"></span>
                </div>

                <div className="mb-4"></div>

                <div className="container">
                    <h2 className="title text-center mb-2">Explore Popular Categories</h2>
                    <div className="cat-blocks-container">
                    <OwlCarousel adClass="owl-carousel owl-simple carousel-equal-height carousel-with-shadow" carouselOptions={ popularCategories }>
                        { data.explore_categories.map( ( cat, index ) =>
                            <Link to={ `${process.env.PUBLIC_URL}/products/shop-by-category` } key={index}  onClick={ev => dispatch(setProductCategoryName(cat.title))} className="cat-block">
                                <div className="position-relative">
                                    <div className="lazy-overlay bg-3"></div>

                                    <LazyLoadImage
                                        src={ `${process.env.PUBLIC_URL}/assets/images/home/cats/${parseInt( index ) + 1}.jpg` }
                                        alt="cat"
                                        width={ 100 }
                                        height={ 100 }
                                        effect="blur"
                                    />
                                </div>
                                <h3 className="cat-block-title">{ cat.title }</h3>
                            </Link>
                        ) }

                    </OwlCarousel>
                    </div>
                </div>

                <div className="bg-light pt-3 pb-5">
                    <div className="container-fluid">
                        <NewArrivalProducts />
                    </div>
                </div>

                <div className="mb-2"></div>


                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6 col-lg-3" >
                            <Banner banner={ data.categoryBanners[ 0 ] } />
                        </div>
                        <div className="col-sm-6 col-lg-3 order-lg-last" >
                            <Banner banner={ data.categoryBanners[ 1 ] } />
                        </div>
                        <div className="col-lg-6" >
                            <Banner banner={ data.categoryBanners[ 2 ] } />
                        </div>
                    </div>
                </div>

                <div className="mb-3"></div>



                <div className="mb-3"></div>



                <div className="mb-3"></div>



                <div className="mb-1"></div>


                <div className="mb-3"></div>



                <div className="mb-3"></div>


                <div className="footer">
                    <Service />
                </div>
            </div>

        </>
    );
}

export default Home;

