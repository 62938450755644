import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {toast} from "react-toastify";
import {findIndex} from "../../../../@titum/utils/TitumUtils_2";

const API_URL = process.env.REACT_APP_API_URL;

export const getCartList = createAsyncThunk('titumEcommerceApp/server/getCartList', async (param, {dispatch}) => {
    try{
        const response = await axios.get(`${API_URL}/customer/get-cartlist`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + localStorage.getItem('weavers_website_access_token'),
            }
        });

        dispatch(setServerCartList(response.data))

    }catch(error){
        throw error;
    }

});

export const serverAddToCart = (product, qty, size) => async (dispatch, getState) => {
    toast.success( "Item Added to Cart" );
    dispatch(setAddToCart({product, qty, size}));
};

export const removeFromCart = (productId) => async (dispatch, getState) => {
    toast.success( "Item Removed from Cart" );
    dispatch(setRemoveFromCart({productId}));
};

export const changeQty = (productId, qty) => async (dispatch, getState) => {
    dispatch(setChangeQty({productId, qty}));
};

export const refreshServerStore = () => async (dispatch, getState) => {
    dispatch(setRefreshStore());
};

export const changeShipping = (shipping) => async (dispatch, getState) => {
    dispatch(setChangeShipping({shipping}));

};


const initialState = {
    serverCartList: [],
    shipping: 0,
};

const serverCartSlice = createSlice({
    name: 'titumEcommerceApp/server',
    initialState,
    reducers: {
        setAddToCart : {
            reducer: (state, action) => {
                const productId = action.payload.product.id;

                if ( findIndex( state.serverCartList, product => product.id === productId ) !== -1 ) {
                    const serverCartList = state.serverCartList.reduce( ( cartAcc, product ) => {
                        if ( product.id === productId ) {
                            cartAcc.push( {
                                ...product,
                                qty: parseInt( product.qty ) + parseInt(action.payload.qty),
                                size : action.payload.size ? action.payload.size : '',
                                sum: ( product.discount ? product.sale_price : product.unit_price ) * ( parseInt( product.qty ) + parseInt(action.payload.qty) ),
                                tot_weight : (product.weight)  * ( parseInt( product.qty ) + parseInt(action.payload.qty) )
                            })
                        } else {
                            cartAcc.push(product)
                        }
                        return cartAcc
                    }, [] )

                    return { ...state, serverCartList }
                }

                return {
                    ...state,
                    serverCartList: [
                        ...state.serverCartList,
                        {
                            ...action.payload.product,
                            size : action.payload.size ? action.payload.size : '',
                            qty: action.payload.qty,
                            sum: ( action.payload.product.discount ? action.payload.product.sale_price : action.payload.product.unit_price ) * action.payload.qty,
                            tot_weight: (action.payload.product.weight) * action.payload.qty
                        }
                    ]
                }
            }
        },
        setRemoveFromCart : {
            reducer: (state, action) => {
                return {
                    ...state,
                    serverCartList: state.serverCartList.filter( item => item.id !== action.payload.productId )
                };
            }
        },
        setChangeQty : {
            reducer: (state, action) => {
                const serverCartList = state.serverCartList.reduce( ( cartAcc, product ) => {
                    if ( product.id === action.payload.productId) {
                        cartAcc.push( { ...product,
                            qty: action.payload.qty,
                            sum: ( product.discount ? product.sale_price : product.unit_price ) * action.payload.qty,
                            tot_weight: (product.weight ) * action.payload.qty,
                        })
                    } else {
                        cartAcc.push( product )
                    }
                    return cartAcc;
                }, [] )
                return { ...state, serverCartList };
            }
        },
        setRefreshStore : {
            reducer: (state, action) => {
                return { ...state, serverCartList: [], shipping: "free" };

            }
        },
        setChangeShipping : {
            reducer: (state, action) => {
                return { ...state, shipping: action.payload.shipping };

            }
        },
        setServerCartList: (state, action) => {
            let serverCartList = action.payload.data
            return {...state, serverCartList}
        },
    }
});

export const {setServerCartList, setAddToCart, setRemoveFromCart, setChangeQty, setRefreshStore, setChangeShipping} = serverCartSlice.actions;



export default serverCartSlice.reducer;
