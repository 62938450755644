import React, { useEffect, useRef, useState } from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import { submitCustomerLogin } from 'app/auth/store/loginSlice';
import Alert from '@material-ui/lab/Alert';
import {Link} from "react-router-dom";
import CartListService from "../../../services/customerService/cartListService/cartListService";
import {getCartList} from "../../products/store/serverCartSlice";
import {SubmitButton} from "../../../../_constants/utilities.contants";

function LoginTab(props) {
    const {cartList} = props;
    const dispatch = useDispatch();
    const login = useSelector(({ auth }) => auth.login);
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState({ email : '', password : ''})

    const formRef = useRef(null);

    useEffect(() => {
            if (login.error) {
                setError(login.error);
                setCredentials({email : '', password : ''})
            }

    }, [login.error]);


    function handleSubmit(event) {
        event.preventDefault();
        setLoading(true)
        dispatch(submitCustomerLogin(credentials)).then((res) => {
            setLoading(false)
            if(res.payload === undefined) {
                CartListService.addToMultipleCartList(cartList).then(() => {
                    dispatch(getCartList());
                })
            }
        })
    }


    return (
        <div>
            {error && (
                <Alert 	className="mb-20" severity="error" style={{ fontSize: '12px', marginTop: '-20px'}}>{error}</Alert>
            )}
            <div>
                <form onSubmit={handleSubmit} ref={formRef} autoComplete="false">
                    <div className="form-group">
                        <label htmlFor="singin-email-2">Email address *</label>
                        <input type="email"
                               className="form-control"
                               id="email"
                               name="email"
                               value={credentials.email}
                               onChange={e => setCredentials({
                                   ...credentials,
                                   email: e.target.value
                               })}
                               required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="singin-password-2">Password *</label>
                        <input 	type={ showPassword ? 'text' : 'password'}
                               className="form-control"
                               id="login-password"
                               name="login-password"
                               value={credentials.password}
                               onChange={e => setCredentials({
                                   ...credentials,
                                   password: e.target.value
                               })}
                               required
                        />

                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="signin-remember-2" />
                            <label className="custom-control-label" htmlFor="signin-remember-2" onClick={() => setShowPassword(!showPassword)}>Show password</label>
                        </div>
                    </div>

                    <div className="form-footer">
                        <SubmitButton text="Log In" loading={loading}/>
                        <Link to="#" className="forgot-link">Forgot Your Password?</Link>
                    </div>
                </form>
            </div>
        </div>
    );
}


export const mapStateToProps = ( state, props ) => ({
    cartList: state.titumEcommerceApp.cart.cartList,
})


export default connect(mapStateToProps, {} )( LoginTab );

