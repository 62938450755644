import { combineReducers } from '@reduxjs/toolkit';
import cart from "./cartSlice";
import product from './productSlice'
import products from './productsSlice';
import filters from './filterSlice';
import order from './orderSlice';
import server from './serverCartSlice';
import orderList from './orderPaySlice'


const productReducer = combineReducers({
    cart,
    product,
    products,
    filters,
    order,
    server,
    orderList
});

export default productReducer;