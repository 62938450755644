import { authRoles } from 'app/auth';
import Login from './Login';

const LoginConfig = {
	settings: {

	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/login',
			component: Login
		}
	]
};

export default LoginConfig;
