import React from 'react';

const aboutConfig = {
    settings: {

    },
    routes: [
        {
            path: '/about',
            component: React.lazy(() => import('./About'))
        },
    ]
};

export default aboutConfig;
