import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_URL;

export const getHotProductCollections = createAsyncThunk('titumEcommerceApp/products/getHotProductCollections', async (param, {dispatch}) => {
    try {
        const response = await axios.get(`${API_URL}/web/hot-product-collections`,{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        });
        const data = await response.data;

        return data;

    }catch(error){
        toast.error('Something went wrong!');
        throw error;
    }

});


const productsSlice = createSlice({
    name: 'titumEcommerceApp/products',
    initialState: null,
    reducers: {},
    extraReducers: {
        [getHotProductCollections.fulfilled]: (state, action) => action.payload,
    }
});

export default productsSlice.reducer;
