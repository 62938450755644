import React from 'react';

import { safeContent } from '@titum/utils/TitumUtils_2';

function IntroSlide( props ) {
    const { slider } = props;
    let image = process.env.PUBLIC_URL + '/' + slider.image;

    return (
        <div className="intro-slide" style={ { backgroundImage: `url(${image})`} } key={ slider.image }>
            <div className="container intro-content">
                <div className="row">
                    <div className="col-auto offset-lg-3 intro-col">
                        <h3 className="intro-subtitle">{ slider.subtitle }</h3>
                        <h1 className="intro-title" dangerouslySetInnerHTML={ safeContent( slider.title ) }></h1>

                        {/*<Link to={ `${process.env.PUBLIC_URL}/shop/sidebar/list` } className="btn btn-outline-primary-2">*/}
                        {/*    <span>Shop Now</span>*/}
                        {/*    <i className="icon-long-arrow-right"></i>*/}
                        {/*</Link>*/}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default IntroSlide;