import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

class CustomerService {

    updateCustomer = async (customer) => {
        try {
            const response = await axios({
                method: 'PUT',
                url: `${API_URL}/customer/update-customer`,
                data : {
                    'first_name' : customer.first_name,
                    'last_name' : customer.last_name,
                    'display_name' : customer.display_name,
                    'email' : customer.email,
                    'password' : customer.new_password,
                    'current_password' : customer.current_password,
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('weavers_website_access_token'),
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    getShipBillAddress = async () => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${API_URL}/customer/get-shipping-billing-address`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('weavers_website_access_token'),
                }
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    };

    getBillAddress = async () => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${API_URL}/customer/get-billing-address`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem('weavers_website_access_token'),
                }
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    };
}

export default new CustomerService();