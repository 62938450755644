import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

class ProductService {

    getNewArrivals = async (rowsPerPage, page) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${API_URL}/web/new-arrivals?per_page=${rowsPerPage}&page=${page}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    };

    getOfferedProducts = async (rowsPerPage, page) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${API_URL}/web/offered-products?per_page=${rowsPerPage}&page=${page}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    };

    getPremierProducts = async (rowsPerPage, page) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${API_URL}/web/premier-products?per_page=${rowsPerPage}&page=${page}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            return response.data;
        } catch (error) {
            throw error;
        }
    };


    getProductsByCategory = async (categoryName) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${API_URL}/web/products-by-category?categoryName=${categoryName}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    getProductsByBrand = async (brandName) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${API_URL}/web/products-by-brand?brandName=${brandName}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    getProductsByBrandRedRock = async () => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${API_URL}/web/products-by-brand-red_rock`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    getProductsByBrandLadyLine = async () => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${API_URL}/web/products-by-brand-lady_line`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };


    getProductsExceptGivenName = async (categoryName, productSku) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${API_URL}/web/select-products-except-given-name?categoryName=${categoryName}&productSku=${productSku}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    searchProducts = async (productName) => {
        try {
            const response = await axios({
                method: 'GET',
                url: `${API_URL}/web/search-products?productName=${productName}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

}

export default new ProductService();