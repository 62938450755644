import React from 'react';
import { Link } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setProductCategoryName} from "../../main/products/store/filterSlice";

import data from "../../mock_data/data.json";

function CategoryMenu( props ) {
    const dispatch = useDispatch();
    const { type } = props;

    function onShowMenu( e ) {
        if ( !document.querySelector( '.category-dropdown.is-on' ).classList.contains( "show" ) ) {
            document.querySelector( '.category-dropdown.is-on' ).classList.add( "show" );
            document.querySelector( '.category-dropdown.is-on > .dropdown-menu' ).classList.add( "show" );
        } else {
            document.querySelector( '.category-dropdown.is-on' ).classList.remove( "show" );
            document.querySelector( '.category-dropdown.is-on > .dropdown-menu' ).classList.remove( "show" );
        }
        e.preventDefault();
    }

    return (
        <div className={ `dropdown category-dropdown ${type === 2 ? 'is-on' : ''}` }>
            <Link to="#" className="dropdown-toggle" onClick={ onShowMenu } role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static" title="Browse Categories">
                Browse Categories
            </Link>

            <div className="dropdown-menu">
                <nav className="side-nav">
                    <ul className="menu-vertical sf-arrows sf-js-enabled">
                        {data.product_categories.map((category,index) =>
                            <li onClick={ev => dispatch(setProductCategoryName(category))} key={ `category-${index}` } ><Link to={ `${process.env.PUBLIC_URL}/products/shop-by-category` }>{category}</Link></li>
                        )}
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default CategoryMenu;