import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({

    titleContainer:{
        flexDirection: 'row',
        marginTop: 24,
        paddingLeft : '10px',
    },
    reportTitle:{
        fontSize: 14,
        textAlign: 'left',
        textTransform: 'uppercase',
        fontStyle: 'bold',
    }
});


const InvoiceTitle = ({title}) => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{title}</Text>
    </View>
);

export default InvoiceTitle