import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function MainMenu( props ) {
    const [ path, setPath ] = useState( "" );

    useEffect( () => {
        setPath( window.location.href );
    } )


    return (
        <nav className="main-nav">
            <ul className="menu sf-arrows">
                <li className={ `megamenu-container` } id="menu-home">
                    <Link to={ `${process.env.PUBLIC_URL}/` } className="sf-arrows">Home</Link>
                </li>
                <li>
                    <Link to={ `${process.env.PUBLIC_URL}/shop/brand` } className="sf-arrows">Brands</Link>
                </li>
                <li>
                    <Link to={ `${process.env.PUBLIC_URL}/shop/premier-products` } className="sf-arrows">Premier Products</Link>
                </li>
                <li>
                    <Link to={ `${process.env.PUBLIC_URL}/shop/offered-products` } className="sf-arrows">Offer</Link>
                </li>
            </ul>
        </nav>
    );
}