import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import {numberWithZero} from "../../../../../../../_constants/utilities.contants";

const borderColor = '#90e5fc'
const styles = StyleSheet.create({

    container : {
        width : '15%',
        marginLeft : '340px'
    },

    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 9,
        fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        textAlign: 'right',
        paddingRight: '5px'
    },

});


const InvoiceTableFooter = (props) => {
    const {invoice} = props

    function getTotal(data) {
        let tot = 0;
        if(data !== null) {
            data.products.map(item => {
                tot += parseInt(item.unit_price * item.product_qty)
            })
            return tot;
        }

    }

    return(
        <View style={styles.container}>
            <View style={styles.row}>
                <Text style={styles.description}>Subtotal</Text>
                <Text style={styles.total}>Rs {numberWithZero(getTotal(invoice))}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description}>Shipping</Text>
                <Text style={styles.total}>Rs {numberWithZero(invoice.shipping_total)}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.description}>Total</Text>
                <Text style={styles.total}>Rs {numberWithZero(invoice.total)}</Text>
            </View>
        </View>
    )
};

export default InvoiceTableFooter