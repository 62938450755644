import React from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from "../../../Titum-layouts/shared-components/PageHeader";
import Breadcrumb from "../../../Titum-layouts/shared-components/Breadcrumb";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Alert from "@material-ui/lab/Alert";
import {Link, Redirect, useParams} from "react-router-dom";

function VerifiedEmail() {

    const routeParams = useParams();
    const  { message } = routeParams;

    return (
        <>
            <Helmet>
                <title>Weavers Online Store for dresses | Sri Lanka</title>
            </Helmet>
            <h1 className="d-none">Weavers Online Store for dresses | Sri Lanka</h1>

            <div className="main">
                <PageHeader title="Registration Successful" subTitle="Registration" />
                <Breadcrumb title="Registration" parent1={ [ "Registration Success", `/` ] } />
                <div className="page-content">
                    <div className="checkout">
                        <div className="container">

                            {message.length == 27 ? (
                                <Card >
                                    <CardActionArea>
                                        <Alert severity="success" style={{ fontSize : '15px'}}>
                                            You have successfully verified your email account!.<br/>
                                            Please click the link to login  <Link to={ `${process.env.PUBLIC_URL}/login` }>login</Link>
                                        </Alert>
                                    </CardActionArea>
                                </Card>
                            ) :(<Redirect to={'/'}/>)}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VerifiedEmail;

