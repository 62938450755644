import React from 'react';

const relaParConfig = {
    settings: {

    },
    routes: [
        {
            path: '/relationship-parties',
            component: React.lazy(() => import('./RelaPar'))
        },
    ]
};

export default relaParConfig;
