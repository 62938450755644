import { useState, useEffect } from "react";

export const usePasswordValidation = (newPassword, confirmPassword, requiredLength) => {
    const [validLength, setValidLength] = useState(null);
    const [hasNumber, setHasNumber] = useState(null);
    const [upperCase, setUpperCase] = useState(null);
    const [lowerCase, setLowerCase] = useState(null);
    const [specialChar, setSpecialChar] = useState(null);
    const [match, setMatch] = useState(null);


    useEffect(() => {

        setValidLength(newPassword.length >= requiredLength ? '' : 'The password should be at least ten characters long');
        setUpperCase(newPassword.toLowerCase() !== newPassword ? '' : 'Upper case letters');
        setLowerCase(newPassword.toUpperCase() !== newPassword ? '' : 'Use lower case letters');
        setHasNumber(/\d/.test(newPassword) ? '' : 'Numbers');
        setMatch(newPassword && newPassword === confirmPassword ? '' : 'confirm password does not match');
        setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(newPassword) ? '' : 'Use symbols like ! " ? $ % ^ &');

    }, [newPassword, confirmPassword, requiredLength]);


    return {validLength, hasNumber, upperCase, lowerCase, match, specialChar};
};