import React, {useEffect, useState} from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PageHeader from '../../../Titum-layouts/shared-components/PageHeader';
import Breadcrumb from '../../../Titum-layouts/shared-components/Breadcrumb';
import {usePasswordValidation} from "../../../../_constants/usePasswordValidation";
import CustomerService from "../../../services/customerService/customerService";
import CircularProgress from "@material-ui/core/CircularProgress";
import {toast} from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import {logoutUser} from "../../../auth/store/userSlice";
import Address from "./components/Address";
import customerService from "../../../services/customerService/customerService";
import Orders from "./components/Orders";

function DashBoard() {
    const user = useSelector(({ auth }) => auth.user);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        'first_name' : user.data.user.firstName, 'last_name' : user.data.user.lastName, 'email' : user.data.user.email,
        'display_name' : user.data.user.email.substring(0, user.data.user.email.lastIndexOf("@")), 'current_password' : '', 'new_password' : '', 'confirm_password' : '',
    })

    const pasValidation = usePasswordValidation(formData.new_password,  formData.confirm_password, 10);
    const {validLength, hasNumber, upperCase, lowerCase, match, specialChar} = pasValidation;
    let isFormValid = false;
    const [errors, setErrors] = useState({ curPassReq : "", curPassErr : "", email : "", errors : {display_name : '', email : ''}});
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const [display, setDisplay] = useState(true);
    const [address, setAddress] = useState('');
    const [data, setData] = useState({shipping_address : [], billing_address : []});


    useEffect(() => {

        if(!formData.current_password) {
            setErrors({...errors, curPassReq: "current password field required"});
        }else{
            setErrors({...errors, curPassReq: ""});
        }

    }, [formData.new_password, formData.confirm_password, formData.current_password, errors.curPassReq, validLength, hasNumber, upperCase, lowerCase, match, specialChar])


    useEffect(() => {
        shipBillAddress().then(() => setLoading(true));
    }, [])

    const shipBillAddress = async () => {
        try{
            const response = await customerService.getShipBillAddress();
            setData(response.shipBilAddress);
        }catch (err) {
            setLoading(false);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if(validatePassword()){
            updateCustomer();
            setFormData({
                ...formData,
                new_password : '',
                current_password: '',
                confirm_password: ''
            })
        }
        if(formData.new_password === '' && formData.current_password ==='' && formData.confirm_password === '')
            updateCustomer();

    }

    const updateCustomer = () => {
        setLoading2(true)
        CustomerService.updateCustomer(formData)
            .then((res) => {
                setLoading2(false)
                toast.success( "Your account updated" );

            })
            .catch((er) => {
                setLoading2(false)
                const { errors } = er.response.data;

                setErrors({
                    ...errors,
                    curPassErr : errors.curPassErr,
                    errors : errors.errors
                })

                setTimeout(() => {
                    setErrors({
                        ...errors,
                        curPassErr : "",
                        errors: {display_name: "", email: ""}
                    })
                }, 5000)
            });
    }

    const validatePassword = () => {
        if(formData.new_password || errors.curPassReq || formData.confirm_password) {
            if(validLength === '' && hasNumber === '' && upperCase === '' && lowerCase === '' && match === '' &&  specialChar === '' && errors.curPassReq === ''){
                isFormValid =true
            }else{
                isFormValid = false
            }
            return isFormValid;
        }

        return isFormValid;

    }

    const handleReload = () => {
        setLoading(false)
        setDisplay(true);
        shipBillAddress().then(() => setLoading(true));
    }

    const handleCancel = () => {
        setDisplay(true);
    }

    return (
        <>
            <Helmet>
                <title>Weavers | Online Fashion Store in Sri Lanka | My Account</title>
            </Helmet>

            <h1 className="d-none">Weavers | Online Fashion Store in Sri Lanka - My Account</h1>

            <div className="main">
                <PageHeader title="My Account" subTitle="Shop" />
                <Breadcrumb title="My Account"  adClass="mb-3" />

                <div className="page-content">
                    <div className="dashboard">
                        <div className="container">
                            <ul className="nav nav-dashboard flex-column mb-3 mb-md-0" role="tablist">
                                <Tabs selectedTabClassName="active show">
                                    <div className="row">
                                        <aside className="col-md-4 col-lg-3">
                                            <TabList>
                                                <Tab className="nav-item">
                                                    <span className="nav-link">Dashboard</span>
                                                </Tab>

                                                <Tab className="nav-item">
                                                    <span className="nav-link">Orders</span>
                                                </Tab>

                                                <Tab className="nav-item">
                                                    <span className="nav-link">Downloads</span>
                                                </Tab>

                                                <Tab className="nav-item">
                                                    <span className="nav-link">Addresses</span>
                                                </Tab>

                                                <Tab className="nav-item">
                                                    <span className="nav-link">Account Details</span>
                                                </Tab>

                                                <Tab className="nav-item">
                                                    <Link to="" className="nav-link" onClick={() => dispatch(logoutUser())}>Sign Out</Link>
                                                </Tab>
                                            </TabList>
                                        </aside>

                                        <div className="col-md-8 col-lg-9" style={ { marginTop: "1rem" } }>
                                            <div className="tab-pane">
                                                <TabPanel>
                                                    <p>Hello <span className="font-weight-normal text-dark">User</span> (not <span className="font-weight-normal text-dark">User</span>? <Link to="#">Log out</Link>)
                                                        <br />
                                                        From your account dashboard you can view your <Link to="#tab-orders" className="tab-trigger-link link-underline">recent orders</Link>, manage your <Link to="#tab-address" className="tab-trigger-link">shipping and billing addresses</Link>, and <Link to="#tab-account" className="tab-trigger-link">edit your password and account details</Link>.</p>
                                                </TabPanel>

                                                <TabPanel>
                                                    <Orders/>
                                                </TabPanel>

                                                <TabPanel>
                                                    <p>No downloads available yet.</p>
                                                    <Link to={ `${process.env.PUBLIC_URL}/shop/sidebar/list` } className="btn btn-outline-primary-2"><span>GO SHOP</span><i className="icon-long-arrow-right"></i></Link>
                                                </TabPanel>

                                                <TabPanel>
                                                    {display ? (
                                                        <>
                                                            <p>The following addresses will be used on the checkout page by default.</p>

                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="card card-dashboard">
                                                                        <div className="card-body">
                                                                            <h3 className="card-title">Billing Address</h3>
                                                                            {data.billing_address.length > 0 ? (
                                                                                data.billing_address.map((item) => (
                                                                                    <p>{item.first_name}<br />
                                                                                        {item.last_name}<br />
                                                                                        {item.address}<br />
                                                                                        {item.province}<br />
                                                                                        {item.email}<br />
                                                                                        <button onClick={() => {
                                                                                            setDisplay(false)
                                                                                            setAddress('Edit Billing');
                                                                                        }} >Edit <i className="icon-edit"></i></button></p>
                                                                                ))
                                                                            ) :(
                                                                                <p>You have not set up this type of address yet.<br />
                                                                                    <button onClick={() => {
                                                                                        setDisplay(false)
                                                                                        setAddress('Add Billing');
                                                                                    }}>Add <i className="icon-edit"></i></button>
                                                                                </p>
                                                                            )}

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <div className="card card-dashboard">
                                                                        <div className="card-body">
                                                                            <h3 className="card-title">Shipping Address</h3>

                                                                            {data.shipping_address.length > 0 ? (
                                                                                data.shipping_address.map((item) => (
                                                                                    <p>{item.first_name}<br />
                                                                                        {item.last_name}<br />
                                                                                        {item.address}<br />
                                                                                        {item.province}<br />
                                                                                        {item.email}<br />
                                                                                        <button onClick={() => {
                                                                                            setDisplay(false)
                                                                                            setAddress('Edit Shipping');
                                                                                        }} >Edit <i className="icon-edit"></i></button></p>
                                                                                ))
                                                                            ) :(
                                                                                <p>You have not set up this type of address yet.<br />
                                                                                    <button onClick={() => {
                                                                                        setDisplay(false)
                                                                                        setAddress('Add Shipping');
                                                                                    }}>Add <i className="icon-edit"></i></button>
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) :(<Address address={address}
                                                                 data={data}
                                                                 handleReload={handleReload}
                                                                 handleCancel={handleCancel}/>)}

                                                </TabPanel>

                                                <TabPanel>
                                                    <form onSubmit={handleSubmit} autoComplete="false">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <label>First Name *</label>
                                                                <input type="text"
                                                                       name="first_name"
                                                                       id="first_name"
                                                                       className="form-control"
                                                                       value={formData.first_name}
                                                                       onChange={e => setFormData({
                                                                           ...formData,
                                                                           first_name: e.target.value
                                                                       })}
                                                                       required />
                                                            </div>

                                                            <div className="col-sm-6">
                                                                <label>Last Name *</label>
                                                                <input type="text"
                                                                       className="form-control"
                                                                       id="last_name"
                                                                       name="last_name"
                                                                       value={formData.last_name}
                                                                       onChange={e => setFormData({
                                                                           ...formData,
                                                                           last_name: e.target.value
                                                                       })}
                                                                       required />
                                                            </div>
                                                        </div>

                                                        <label>Display Name *</label>
                                                        <input type="text"
                                                               className="form-control"
                                                               id="display_name"
                                                               name="display_name"
                                                               value={formData.display_name}
                                                               onChange={e => setFormData({
                                                                   ...formData,
                                                                   display_name: e.target.value
                                                               })}
                                                               required />
                                                        <span className='text-danger'>{errors.errors.display_name ? errors.errors.display_name : ''}</span>
                                                        <small className="form-text">This will be how your name will be displayed in the account section and in reviews</small>

                                                        <label>Email address *</label>
                                                        <input type="email"
                                                               className="form-control"
                                                               id="email"
                                                               name="email"
                                                               value={formData.email}
                                                               onChange={e => setFormData({
                                                                   ...formData,
                                                                   email: e.target.value
                                                               })}
                                                               required />
                                                        <span className='text-danger'>{errors.errors.email ? errors.errors.email : ''}</span>

                                                        <label>Current password (leave blank to leave unchanged)</label>
                                                        <input type="password"
                                                               id="current_password"
                                                               name="current_password"
                                                               value={formData.current_password}
                                                               onChange={e => setFormData({
                                                                   ...formData,
                                                                   current_password: e.target.value
                                                               })}
                                                               className="form-control"
                                                        />
                                                        <span className='text-danger'>{formData.new_password ? errors.curPassReq + ' ' : ''}</span>
                                                        <span className='text-danger'>{errors.curPassErr.length === 30 ? errors.curPassErr : ''}</span><br/>

                                                        <label>New password (leave blank to leave unchanged)</label>
                                                        <input type={ showPassword ? 'text' : 'password'}
                                                               id="new_password"
                                                               name="new_password"
                                                               className="form-control"
                                                               value={formData.new_password}
                                                               onChange={e => setFormData({
                                                                   ...formData,
                                                                   new_password: e.target.value
                                                               })}
                                                        />
                                                        <div className='text-danger'>{ formData.new_password || formData.current_password || formData.confirm_password ?
                                                            validLength +' '+ lowerCase +' '+ upperCase +' '+  hasNumber+', '+ specialChar : ''}</div>


                                                        <label>Confirm new password</label>
                                                        <input type={ showPassword ? 'text' : 'password'}
                                                               id="confirm_password"
                                                               name="confirm_password"
                                                               className="form-control mb-2"
                                                               value={formData.confirm_password}
                                                               onChange={e => setFormData({
                                                                   ...formData,
                                                                   confirm_password: e.target.value
                                                               })}
                                                        />
                                                        <div className='text-danger'>{formData.new_password || formData.current_password || formData.confirm_password ? match : ''}</div>

                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="signin-remember-2" />
                                                            <label className="custom-control-label" htmlFor="signin-remember-2" onClick={() => setShowPassword(!showPassword)}>Show password</label>
                                                        </div>

                                                        <button type="submit" className="btn btn-outline-primary-2">
                                                            <span>SAVE CHANGES</span>
                                                            {loading2 ? (<CircularProgress size={25} style={{ marginLeft : '10px'}}/>) : (<i className="icon-long-arrow-right"></i>)}
                                                        </button>
                                                    </form>
                                                </TabPanel>
                                            </div>
                                        </div>
                                    </div>
                                </Tabs>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashBoard;
