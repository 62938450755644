import Home from './Home';

const homeConfig = {
    settings: {

    },
    routes: [
        {
            path: '/',
            exact : true,
            component: Home
        }
    ]
};

export default homeConfig;
