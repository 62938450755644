import React from 'react';

const offeredProductsConfig = {
    settings: {

    },
    routes: [
        {
            path: '/shop/offered-products',
            component: React.lazy(() => import('./OfferedProducts')),
            exact : true,
        },
    ]
};

export default offeredProductsConfig;
