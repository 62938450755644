import React from 'react';

const brandListConfig = {
    settings: {

    },
    routes: [
        {
            path: '/products/shop-by-brand',
            component: React.lazy(() => import('./ShopList')),
        },
    ]
};

export default brandListConfig;
