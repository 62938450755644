import { combineReducers } from '@reduxjs/toolkit';
import auth from 'app/auth/store';
import productReducer from "../main/products/store";
import titum from './titum';

const createReducer = asyncReducers =>
	combineReducers({
		auth,
		titum,
		productReducer,
		...asyncReducers
	});

export default createReducer;
