import React from 'react';

const checkoutConfig = {
    settings: {

    },
    auth:  ['customer'],
    routes: [
        {
            path: '/checkout/order-pay/pay-for-order/:reference',
            component: React.lazy(() => import('./Checkout')),
        },
    ]
};

export default checkoutConfig;
