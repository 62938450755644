import React, {useEffect} from 'react';
import Provider from 'react-redux/es/components/Provider';
import AppContext from './AppContext';
import TitumAuthorization from '@titum/core/TitumAuthorization';
import Layout from 'app/Titum-layouts/Layout';
import {scrollTop, preventProductDefault, removePreventProductDefault, initFunctions, stickyHeaderHandler } from '@titum/utils/TitumUtils_2';
import {BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, {persist} from './store';
import { Auth } from './auth';
import routes from '../titum-configs/routesConfig';

const App = () => {

    initFunctions();

    useEffect( () => {
        scrollTop();

        stickyHeaderHandler();

        window.addEventListener( 'scroll', stickyHeaderHandler, true );
        preventProductDefault();

        return () => {
            window.removeEventListener( 'scroll', stickyHeaderHandler );

            removePreventProductDefault();
        }
    }, [] )



    return (
        <AppContext.Provider
            value={{
                routes
            }}
        >
            <Provider store={store}>
                <PersistGate persistor={ persist } loading={null}>
                <Auth>
                  <Router>
                        <TitumAuthorization>
                            <Layout/>
                        </TitumAuthorization>
                    </Router>
                </Auth>
                </PersistGate>
            </Provider>
        </AppContext.Provider>
    );
};

export default App;
