import React from 'react';

const conceptConfig = {
    settings: {

    },
    routes: [
        {
            path: '/concept',
            component: React.lazy(() => import('./Concept'))
        },
    ]
};

export default conceptConfig;
