import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import {useDispatch} from "react-redux";
import {setProductCategoryName} from "../../main/products/store/filterSlice";

import MobileMainNav from './MobileMainNav';
import data from "../../mock_data/data.json";

function MobileMenu() {

    const dispatch = useDispatch();

    return (
        <div className="mobile-menu-container">
            <div className="mobile-menu-wrapper">
                <span className="mobile-menu-close"><i className="icon-close"></i></span>

                <Tabs defaultIndex={ 0 } selectedTabClassName="show">
                    <TabList className="nav nav-pills-mobile nav-border-anim" role="tablist">
                        <Tab className="nav-item">
                            <span className="nav-link">Menu</span>
                        </Tab>

                        <Tab className="nav-item">
                            <span className="nav-link">Categories</span>
                        </Tab>
                    </TabList>

                    <div className="tab-content">
                        <TabPanel>
                            <MobileMainNav />
                        </TabPanel>

                        <TabPanel>
                            <nav className="mobile-cats-nav">
                                <ul className="mobile-cats-menu">
                                {data.product_categories.map((category,index) =>
                                    <li onClick={ev => dispatch(setProductCategoryName(category))} key={ `category-${index}` } ><Link to={ `${process.env.PUBLIC_URL}/products/shop-by-category` }>{category}</Link></li>
                                )}
                                </ul>
                            </nav>
                        </TabPanel>
                    </div>
                </Tabs>

                <div className="social-icons">
                    <Link to="#" className="social-icon" target="_blank" title="Facebook"><i className="icon-facebook-f"></i></Link>
                    <Link to="#" className="social-icon" target="_blank" title="Twitter"><i className="icon-twitter"></i></Link>
                    <Link to="#" className="social-icon" target="_blank" title="Instagram"><i className="icon-instagram"></i></Link>
                    <Link to="#" className="social-icon" target="_blank" title="Youtube"><i className="icon-youtube"></i></Link>
                </div>
            </div>
        </div>
    )
}

export default MobileMenu;