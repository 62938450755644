import React, { useState, useEffect } from 'react';
import { Tabs} from 'react-tabs';
import ProductNine from '../../../features/products/ProductNine';
import withReducer from 'app/store/withReducer';
import reducer from '../store';
import TitumLoading from "../../../../@titum/core/TitumLoading";
import ProductService from "../../../services/customerService/productService/productService";

function NewArrivalProducts( props ) {

    const { toggleWishlist, showQuickViewModal, addToCompare } = props;
    const [ loadedCount, setLoadedCount ] = useState( 10 );
    const [page, setPage] = useState(0);
    const [ hasMore, setHasMore ] = useState( true );
    const [ loading, setLoading ] = useState( false );
    const [ loading2, setLoading2 ] = useState( false );
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [total, setTotal] = useState(0);

    let timer;

    const [newArrivalProducts, setNewArrivalProducts] = useState([]);

    const addProducts = (data) => {
        data.map((d, i) => {
            setNewArrivalProducts((n) => [...n, d]);
        })
    };

    useEffect(() => {
        const newArrivals = async () => {
            try{
                const response = await ProductService.getNewArrivals(rowsPerPage, page);
                addProducts(response.data);
                setTotal(response.meta.total);

            }catch (err) {
                setLoading(false);
            }
        };

        newArrivals().then(() => setLoading(true));

    }, [page]);


    function loadMore() {
        setPage(2)
        // fake async api. products should be fetched from backend
        if ( loadedCount < total) {
            setLoading2( true );

            timer = setTimeout( () => {
                setLoadedCount( loadedCount + 5 );
                setLoading2( false );
            }, 4000 );
        } else {
            setHasMore( false );
        }
    }

    useEffect( () => {
        return () => {
            if ( timer ) clearTimeout( timer );
        }
    }, [] )


    return (
        <React.Fragment>
            <Tabs defaultIndex={ 0 } selectedTabClassName="show">
                <div className="heading heading-center mb-3">
                    <h2 className="title nav-link">NEW ARRIVALS</h2>
                </div>

                { loading ?
                    <div className="products">
                        <div className="row justify-content-center">
                            { newArrivalProducts.map((product, index) =>
                                <div className="col-6 col-md-4 col-lg-3 col-xl-5col" key={ product.id + product.name }>
                                    <ProductNine
                                        product={ product }
                                        key={ index + product.name }
                                        onToggleWishlist={ toggleWishlist }
                                        onAddToCompare={ addToCompare }
                                        showQuickView={ showQuickViewModal } />
                                </div>
                            )}
                        </div>
                    </div> : <TitumLoading/>}
            </Tabs>

            <div className="more-container text-center mt-2">
                {
                    hasMore ?
                        <button className="btn btn-outline-dark-3 btn-more" onClick={ loadMore }><span>Load more</span>
                            { loading2 ?
                                <i className="icon-refresh load-more-rotating"></i> :
                                <i className="icon-long-arrow-right"></i>
                            }
                        </button>
                        : ''
                }
            </div>
        </React.Fragment>
    )
}

export default withReducer('titumEcommerceApp', reducer)(NewArrivalProducts);

