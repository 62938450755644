import React from 'react';
import { Page, Document, Image, StyleSheet } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'
import InvoiceTable from "./InvoiceTable";


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 9,
        paddingTop: 15,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: '60%',
        height: 70
    }
});

const Invoice = ({invoice}) => {

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Image style={styles.logo} src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} />
                {invoice && (
                    <>
                        <InvoiceTitle title='Invoice'/>
                        <BillTo invoice={invoice}/>
                        <InvoiceTable invoice={invoice}/>
                        <InvoiceThankYouMsg />
                    </>
                )}
            </Page>
        </Document>
    );

};

export default Invoice