import cartConfig from "./shop/card/cartConfig";
import Error404PageConfig from "./errors/404/Error404PageConfig";
import dashboardConfig from "./dashboard/dashboardConfig";
import checkoutConfig from "./shop/checkout/checkoutConfig";
import orderPayConfig from "./shop/order-pay/orderPayConfig";
import paymentReceiptConfig from "./payment-receipt/paymentReceiptConfig";
import aboutConfig from "./about/AboutConfig";
import serviceConfig from "./service/ServiceConfig";
import polGuidConfig from "./policy-guideline/PolGuidConfig";
import relaParConfig from "./relationship-parties/RelaParConfig";
import techCompeConfig from "./technology-comp/TechCompeConfig";
import privacySecuConfig from "./privacy-security/PrivacySecuConfig";
import conceptConfig from "./concept/ConceptConfig";

const pagesConfigs = [
    cartConfig,
    Error404PageConfig,
    dashboardConfig,
    checkoutConfig,
    orderPayConfig,
    paymentReceiptConfig,
    aboutConfig,
    serviceConfig,
    polGuidConfig,
    relaParConfig,
    techCompeConfig,
    privacySecuConfig,
    conceptConfig
];

export default pagesConfigs;
