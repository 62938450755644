import React from 'react';

const categoryListConfig = {
    settings: {

    },
    routes: [
        {
            path: '/products/shop-by-category',
            component: React.lazy(() => import('./ShopList')),
        },
    ]
};

export default categoryListConfig;
