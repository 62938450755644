import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import MainMenu from './MainMenu';
import CategoryMenu from './CategoryMenu';
import CartMenu from "./CartMenu";
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from 'app/auth/store/userSlice';
import TitumSearch from "../../../@titum/core/TitumSearch";
import TitumMobileSearch from "../../../@titum/core/TitumMobileSearch";

function Header( props ) {
    const dispatch = useDispatch();
    const user = useSelector(({ auth }) => auth.user);

    const { container = "container" } = props;
    const [ path, setPath ] = useState( "" );

    return (
        <header className="header header-10 header-intro-clearance">
            <div className="header-top">
                <div className={ container }>
                    <div className="header-left">
                        <a href="tel:+94 772 575 542"><i className="icon-phone"></i>Call: +94 772 575 542</a> <span style={{ margin : '0 5px'}}> | </span>
                        <a href="tel:+94 112 193 114">+94 112 193 114</a>

                    </div>

                    {/*<div  id="mobile-login-signup">*/}
                    {/*    <ul className="top-menu-mobile">*/}
                    {/*            {user.role.length !== 0 ? (*/}
                    {/*                <li>*/}
                    {/*                    <div className="header-dropdown">*/}
                    {/*                        <Link to="#">{user.data.user.firstName}</Link>*/}
                    {/*                        <div className="header-menu">*/}
                    {/*                            <ul>*/}
                    {/*                                <li><Link to="/dashboard">My account</Link></li>*/}
                    {/*                                <li><Link to="#" onClick={() => dispatch(logoutUser())}>Logout</Link></li>*/}
                    {/*                            </ul>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </li>*/}
                    {/*            ):(*/}
                    {/*                <li className="login">*/}
                    {/*                    <Link to="/login" data-toggle="modal">Login</Link>*/}
                    {/*                </li>*/}
                    {/*            )}*/}
                    {/*    </ul>*/}
                    {/*</div>*/}

                    <div className="header-right">
                        <ul className="top-menu">
                            <li>
                                <Link to="#">Links</Link>
                                <ul>
                                    <li>
                                        <div className="header-dropdown">
                                            <Link to="#">LKR</Link>
                                            <div className="header-menu">
                                                <ul>
                                                    <li><Link to="#">LKR</Link></li>
                                                    <li><Link to="#">Usd</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="header-dropdown">
                                            <Link to="#">English</Link>
                                            <div className="header-menu">
                                                <ul>
                                                    <li><Link to="#">English</Link></li>
                                                    <li><Link to="#">Tamil</Link></li>
                                                    <li><Link to="#">Singala</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li>
                                    {user.role.length !== 0 ? (
                                            <li>
                                                <div className="header-dropdown">
                                                    <Link to="#">{user.data.user.firstName}</Link>
                                                    <div className="header-menu">
                                                        <ul>
                                                            <li><Link to="/dashboard">My account</Link></li>
                                                            <li><Link to="#" onClick={() => dispatch(logoutUser())}>Logout</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                    ):(
                                        <li className="login">
                                            <Link to="/login" data-toggle="modal">Sign in / Sign up</Link>
                                        </li>
                                    )}

                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


            <div className="header-middle">
                <div className={ container }>
                    <div className="header-left">
                        <button className="mobile-menu-toggler">
                            <span className="sr-only">Toggle mobile menu</span>
                            <i className="icon-bars"></i>
                        </button>

                        <Link to={ `${process.env.PUBLIC_URL}` } className="logo">
                            <img src={ `${process.env.PUBLIC_URL}/assets/images/logo.png` } alt="Molla Logo" width="200" height="25" />
                        </Link>
                    </div>

                    <TitumSearch/>

                    <div className="header-right mr-3">
                        <div className="header-dropdown-link">

                            {/*<Link to={ `${process.env.PUBLIC_URL}/shop/wishlist` } className="wishlist-link">*/}
                            {/*    <i className="icon-heart-o"></i>*/}
                            {/*    <span className="wishlist-count">4</span>*/}
                            {/*    <span className="wishlist-txt">Wishlist</span>*/}
                            {/*</Link>*/}
                            <CartMenu />
                        </div>
                    </div>
                </div>
                <TitumMobileSearch/>
            </div>

            <div className="header-bottom sticky-header">
                <div className={ container }>
                    <div className="header-left">
                        <CategoryMenu type={ 2 } />
                    </div>

                    <div className="header-center">
                        <MainMenu />
                    </div>

                    <div className="header-right">
                        <i className="la la-lightbulb-o"></i><p>Clearance Up to 30% Off</p>
                    </div>
                </div>
            </div>



        </header>
    )
}

export default Header ;

