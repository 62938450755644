import React, {useEffect, useState} from 'react';
import './style.css';
import orderService from "../../../../services/customerService/orderService/orderService";
import {Loader, numberWithZero} from "../../../../../_constants/utilities.contants";
import moment from 'moment';
import ViewOrder from "./components/ViewOrder";
import {withRouter} from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Invoice from "./components/payment-invoice/Invoice";
import {toast} from "react-toastify";


function Orders(props) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [orderView, setOrderView] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    useEffect(() => {
        getOrders();

    }, [])


    const getOrders = () => {

        orderService.getAll().then((res) => {
            if(res.status === 200){
                setData(res.orders)
                setLoading(true)
            }
        })
    }

    useEffect(() => {
    }, [data])

    const handleBack = () => {
        setOrderView(false);
    }


    return (
        <>
            {!orderView ? (
                <>
                    <table id="table-6">
                        <tr>
                            <th>Order</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Total</th>
                            <th>Actions</th>

                        </tr>
                        {loading ? (
                            data[0] !== undefined && (
                                data.map((order) => (
                                    <tr key={order.id}>
                                        <td>#{order.order_code}</td>
                                        <td>{moment( order.date).format('ll')}</td>
                                        <td>{order.status.name}</td>
                                        <td>Rs {numberWithZero(order.total)}</td>
                                        <td>
                                            {order.is_paid != 1 ? (
                                                <>
                                                    <button type="button" onClick={() => props.history.push(`/checkout/order-pay/pay-for-order/${order.order_code}`)} >Pay</button>
                                                    <button type="button" onClick={() => {
                                                        orderService.cancelOrder(order.id).then(() => {
                                                            toast.success( "Order canceled" );
                                                            getOrders();
                                                        })
                                                    }}>Cancel</button>
                                                </>
                                            ) :(
                                                <PDFDownloadLink document={<Invoice invoice={order} />} fileName="invoice.pdf">
                                                    <button type="button" className="">Invoice</button>
                                                </PDFDownloadLink>
                                            ) }

                                            <button type="button" onClick={() => {
                                                setOrderView(true)
                                                setSelectedOrder(order);
                                            }}>View</button>

                                        </td>

                                    </tr>
                                ))
                            )
                        ) :(<Loader/>)}

                    </table>

                    <div  className="mobile-table">
                        <table>
                            {loading ? (
                                data.map((order) => (
                                    <tbody key={order.id}>
                                    <tr>
                                        <th>Order</th>
                                        <td>#{order.order_code}</td>
                                    </tr>
                                    <tr>
                                        <th>Date</th>
                                        <td>{moment( order.date).format('ll')}</td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td>{order.status.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Total</th>
                                        <td>Rs {numberWithZero(order.total)}</td>
                                    </tr>
                                    <tr>
                                        <th>Actions</th>
                                        <td>
                                            {order.is_paid != 1 ? (
                                                <>
                                                    <button type="button" onClick={() => props.history.push(`/checkout/order-pay/pay-for-order/${order.order_code}`)} >Pay</button>
                                                    <button type="button" onClick={() => {
                                                        orderService.cancelOrder(order.id).then(() => {
                                                            toast.success( "Order canceled" );
                                                            getOrders();
                                                        })
                                                    }}>Cancel</button>
                                                </>
                                            ) :(
                                                <PDFDownloadLink document={<Invoice invoice={order} />} fileName="invoice.pdf">
                                                    <button type="button" className="">Invoice</button>
                                                </PDFDownloadLink>
                                            ) }

                                            <button type="button" onClick={() => {
                                                setOrderView(true)
                                                setSelectedOrder(order);
                                            }}>View</button>

                                        </td>
                                    </tr>

                                    </tbody>
                                ))
                            ) :(<Loader/>)}
                        </table>
                    </div>
                </>
            ) : (<ViewOrder data={selectedOrder} handleBack={handleBack}/>)}
        </>
    );
}

export default withRouter(Orders);
