import React from 'react';
import data from '../../mock_data/data.json';
import { serviceSlider } from '@titum/settings';
import OwlCarousel from "./OwlCarousel";

function Service( props ) {

    const { iconAdClass = "text-dark" } = props;

    return (
        <div className="icon-boxes-container bg-transparent">
            <div className="container">
                <OwlCarousel adClass="owl-simple" carouselOptions={ serviceSlider }>
                    { data.services.map( ( item, index ) =>
                        <div className="icon-box icon-box-side">
                                <span className={ `icon-box-icon ${iconAdClass}` }>
                                    <i className={ item.icon }></i>
                                </span>
                            <div className="icon-box-content">
                                <h3 className="icon-box-title">{ item.title }</h3>
                                <p>{ item.subtitle }</p>
                            </div>
                        </div>
                    ) }
                </OwlCarousel>
            </div>
        </div>
    )
}

export default React.memo( Service );
