import React from 'react';

const polGuidConfig = {
    settings: {

    },
    routes: [
        {
            path: '/policy-guideline',
            component: React.lazy(() => import('./PolGuid'))
        },
    ]
};

export default polGuidConfig;
