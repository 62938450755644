import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'
import {persistReducer} from "redux-persist";

export const addToOrderList = (orderList) => async (dispatch, getState) => {
    dispatch(setAddToOrderList(orderList));
};


export const addToFormData = (formData) => async (dispatch, getState) => {
    dispatch(setAddToFormData(formData));
};

export const addToOrderReference = (orderReference) => async (dispatch, getState) => {
    dispatch(setAddToFormData(orderReference));
};

export const refreshStore = () => async (dispatch, getState) => {
    dispatch(setRefreshStore());
};

const initialState = {
    orderPay: [],
    formData : {},
    orderReference : ''
};

const orderListSlice = createSlice({
    name: 'titumEcommerceApp/orderList',
    initialState,
    reducers: {
        setAddToOrderList : {
            reducer: (state, action) => {
                let orderList = action.payload;
                console.log(orderList);

                return {
                    ...state,
                    orderPay: orderList
                }
            }
        },
        setAddToFormData : {
            reducer: (state, action) => {
                let formData = action.payload;
                return {
                    ...state,
                    formData: formData
                }
            }
        },
        setAddToOrderReference : {
            reducer: (state, action) => {
                let orderReference = action.payload;
                return {
                    ...state,
                    orderReference: orderReference
                }
            }
        },
        setRefreshStore : {
            reducer: (state, action) => {
                return { ...state, orderPay: [], formData : {}};

            }
        },
    }
});

const persistConfig = {
    keyPrefix: "weavers-",
    key: "orderList",
    storage
}

export const {setAddToOrderList, setRefreshStore, setAddToFormData} = orderListSlice.actions;

export default persistReducer( persistConfig, orderListSlice.reducer );
