import React from 'react';

const cartConfig = {
    settings: {

    },
    auth:  ['customer'],
    routes: [
        {
            path: '/shop/checkout',
            component: React.lazy(() => import('./Checkout'))
        },
    ]
};

export default cartConfig;
