import React from 'react';

const serviceConfig = {
    settings: {

    },
    routes: [
        {
            path: '/service',
            component: React.lazy(() => import('./Service'))
        },
    ]
};

export default serviceConfig;
