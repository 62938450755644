import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {toast} from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;


export const saveOrder = createAsyncThunk('titumEcommerceApp/order/saveOrder', async (orderData, {dispatch}) => {
    try{

        const response = await axios.post(`${API_URL}/customer/order`,  {
            data : {
                product_list : orderData.cartlist,
                order_data : orderData.formData,
                order_id : orderData.order_id,
                total : orderData.total,
                subtotal : orderData.subtotal,
                paymentMethod : orderData.paymentMethod,
                shipping_total : orderData.shipping_total
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization' : 'Bearer ' + localStorage.getItem('weavers_website_access_token'),
            }
        });
        const data = await response.data;

        return data;

    }catch(error){
        toast.warning( "Something went wrong" );

        throw error;
    }

});


const orderSlice = createSlice({
    name: 'titumEcommerceApp/order',
    initialState: null,
    extraReducers: {
        [saveOrder.fulfilled]: (state, action) => action.payload,
    }
});

export default orderSlice.reducer;


