import React from "react";
import {findIndex} from "@titum/utils/TitumUtils_2";
import CartListService from "../app/services/customerService/cartListService/cartListService";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid/Grid";


export function addToCartToServer(cartList, productId, unit_price, size, weight) {

    if(cartList.length > 0) {
        if ( findIndex( cartList, pt => pt.id === productId ) !== -1 ) {
            cartList.reduce((cartAcc, pt) => {
                if (pt.id === productId) {
                    const data = {
                        'product_id': productId,
                        'qty': parseInt(pt.qty) + parseInt(1),
                        'sum': (pt.discount ? pt.sale_price : pt.unit_price) * (parseInt(pt.qty) + parseInt(1)),
                        'size' : size ? size : "",
                        'tot_weight' : (pt.weight)  * ( parseInt( pt.qty ) + parseInt(1) )

                    }
                    CartListService.addToCartList(data).then(() => {})
                }

            }, [])

        }else{
            const data = {
                'product_id' : productId,
                'qty' : 1,
                'sum' : unit_price,
                'size' : size,
                'tot_weight' : weight,

            }
            CartListService.addToCartList(data).then(() => {})
        }

    }else{
        const data = {
            'product_id' : productId,
            'qty' : 1,
            'sum' : unit_price
        }
        CartListService.addToCartList(data).then(() => {})
    }

}

export function getUnique(arr, comp) {
    // store the comparison  values in array
    const unique =  arr.map(e => e[comp])
        // store the indexes of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)
        // eliminate the false indexes & return unique objects
        .filter((e) => arr[e]).map(e => arr[e]);

    return unique;
}

export function getDuplicatedValue (arr, comp) {

    // store the comparison  values in array
    const unique =  arr.map(e => e[comp])
        // store the indexes of the unique objects
        .map((e, i, final) => final.indexOf(e) !== i && i)
        // eliminate the false indexes & return unique objects
        .filter((e) => arr[e]).map(e => arr[e]);

    return unique[0];
}

export function Loader() {
    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginTop: "30px", marginBottom: "30px" }}
        >
            <CircularProgress size={25}/>
        </Grid>
    );
}

export function SubmitButton(props) {
    const {loading, text} = props;
    return (
        <button type="submit" className="btn btn-outline-primary-2" disabled={loading}>
            <span>{text}</span>
            {loading ? (<CircularProgress size={25} style={{ marginLeft : '10px'}}/>) : (<i className="icon-long-arrow-right"></i>)}
        </button>
    );
}

export function numberWithZero(x) {
    return x.toLocaleString("en", {
        useGrouping: true,
        minimumFractionDigits: 2,
    });
}
