import FuseUtils from '@titum/utils';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import _ from '@lodash';
import React, {useEffect, useReducer, useRef, useState} from 'react';
import Autosuggest from 'react-autosuggest';
import { useSelector } from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import ProductService from "../../../app/services/customerService/productService/productService";
import {CircularProgress} from "@material-ui/core";

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;


function renderSuggestion(suggestion, { query, isHighlighted }) {
	const matches = match(suggestion.name, query);
	const parts = parse(suggestion.name, matches);

	return (
		<div>
			<div className="header-search header-search-visible">
				<MenuItem selected={isHighlighted} component="div">
					<ListItemIcon className="min-w-40">
						{suggestion.images ? (
							<img src={`${STORAGE_URL}/${suggestion.images[0].url}` } alt="product" className='search-image' />

						) : (
							<span className="text-20 w-24 font-bold uppercase text-center" style={{ fontSize : '15px'}}>{suggestion.name[0]}</span>
						)}
					</ListItemIcon>
					<ListItemText
						primary={parts.map((part, index) =>
								part.highlight ? (
									<span key={String(index)} style={{ fontWeight: 600, fontSize : '15px' }}>
							{part.text}
						</span>
								) : (
									<strong key={String(index)} style={{ fontWeight: 300, fontSize : '15px' }}>
										{part.text}
									</strong>
								)
						)}
					/>
				</MenuItem>
			</div>
		</div>
	);
}

function getSuggestions(value, data) {
	const inputValue = _.deburr(value.trim()).toLowerCase();
	const inputLength = inputValue.length;
	let count = 0;

	return inputLength === 0
		? []
		: data.filter(suggestion => {
			const keep = count < 10 && match(suggestion.name, inputValue).length > 0;

			if (keep) {
				count += 1;
			}

			return keep;
		});
}

function getSuggestionValue(suggestion) {
	return suggestion.name;
}

const useStyles = makeStyles(theme => ({
	root: {},
	container: {
		position: 'relative'
	},
	suggestionsContainerOpen: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing(),
		left: 0,
		right: 0
	},
	suggestion: {
		display: 'block'
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: 'none'
	},
	input: {
		padding : '10px',
		transition: theme.transitions.create(['background-color'], {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.short
		}),
		'&:focus': {
			backgroundColor: theme.palette.background.paper
		},
		fontSize : '15px',
		border : 'red'
	}
}));

const initialState = {
	searchText: '',
	search: false,
	navigation: null,
	suggestions: [],
	noSuggestions: false
};

function TitumSearch(props) {
	const [data, setData] = useState([]);
	const[loading, setLoading] = useState(false);


	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		const searchProducts = async () => {
			setLoading(true)
			try{
				const response = await ProductService.searchProducts(state.searchText)
				setData(response.products)
				setLoading(false);

			}catch (err) {
				setLoading(false)
			}
		};

		if(state.searchText) {
			searchProducts();
		}



	}, [state.searchText])


	function renderInputComponent(inputProps) {
		const { variant, classes, inputRef = () => {}, ref, ...other} = inputProps;
		return (
			<div className="w-full relative">
				<TextField
					fullWidth
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon style={{ fontSize : '15px'}} />
							</InputAdornment>
						),
						endAdornment: loading && (
							<InputAdornment position="end">
								<CircularProgress size={15}/>
							</InputAdornment>
						),
						disableUnderline: true,
						inputRef: node => {
							ref(node);
							inputRef(node);
						},
						classes: {
							input: clsx(classes.input)
						}
					}}
					variant="outlined"
					{...other}
				/>
			</div>
		);
	}

	function reducer(state, action) {
		switch (action.type) {
			case 'open': {
				return {
					...state,
					opened: true
				};
			}
			case 'close': {
				return {
					...state,
					opened: false,
					searchText: ''
				};
			}
			case 'setSearchText': {
				return {
					...state,
					searchText: action.value
				};
			}
			case 'setNavigation': {
				return {
					...state,
					navigation: action.value
				};
			}
			case 'updateSuggestions': {
				const suggestions = getSuggestions(action.value, data);
				const isInputBlank = action.value.trim() === '';
				const noSuggestions = !isInputBlank && suggestions.length === 0;

				return {
					...state,
					suggestions,
					noSuggestions
				};
			}
			case 'clearSuggestions': {
				return {
					...state,
					suggestions: [],
					noSuggestions: false
				};
			}
			case 'decrement': {
				return { count: state.count - 1 };
			}
			default: {
				throw new Error();
			}
		}

	}

	const classes = useStyles(props);
	const suggestionsNode = useRef(null);
	const popperNode = useRef(null);


	function showSearch(ev) {
		ev.stopPropagation();
		dispatch({ type: 'open' });
		document.addEventListener('keydown', escFunction, false);
	}

	function hideSearch() {
		dispatch({ type: 'close' });
		console.info('hide');
		document.removeEventListener('keydown', escFunction, false);
	}

	function escFunction(event) {
		if (event.keyCode === 27) {
			hideSearch();
		}
	}

	function handleSuggestionsFetchRequested({ value }) {
		dispatch({
			type: 'updateSuggestions',
			value
		});
	}

	function handleSuggestionSelected(event, { suggestion }) {
		event.preventDefault();
		event.stopPropagation();
		if (!suggestion.sku) {
			props.history.push(`/`);

		}
		//const url = `${process.env.PUBLIC_URL}/product/${suggestion.name.replace(/ /g, '-').toLowerCase()}`;
		//window.open(url);
		props.history.push(`${process.env.PUBLIC_URL}/product/${suggestion.name.replace(/ /g, '-').toLowerCase()}/${suggestion.sku}`);
		hideSearch();
	}

	function handleSuggestionsClearRequested() {
		dispatch({
			type: 'clearSuggestions'
		});
	}

	function handleChange(event) {

		dispatch({
			type: 'setSearchText',
			value: event.target.value
		});
	}

	function handleClickAway(event) {
		return (
			state.opened &&
			(!suggestionsNode.current || !suggestionsNode.current.contains(event.target)) &&
			hideSearch()
		);
	}

	const autosuggestProps = {
		renderInputComponent,
		highlightFirstSuggestion: true,
		suggestions: state.suggestions,
		onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
		onSuggestionsClearRequested: handleSuggestionsClearRequested,
		onSuggestionSelected: handleSuggestionSelected,
		getSuggestionValue,
		renderSuggestion
	};

	return (
		<div className="header-center" >
			<div className="header-search header-search-extended header-search-visible header-search-no-radius d-none d-lg-block">
				<div className={clsx(props.className)} ref={popperNode} >
					<Autosuggest
						{...autosuggestProps}
						inputProps={{
							variant: props.variant,
							classes,
							placeholder: props.placeholder,
							value: state.searchText,
							onChange: handleChange,
							onFocus: showSearch,
							InputLabelProps: {
								shrink: true
							},
							autoFocus: false
						}}
						theme={{
							container: 'flex flex-1 w-full',
							suggestionsList: classes.suggestionsList,
							suggestion: classes.suggestion
						}}
						renderSuggestionsContainer={options => (
							<Popper
								anchorEl={popperNode.current}
								open={Boolean(options.children) || state.noSuggestions}
								popperOptions={{ positionFixed: true }}
								className="z-9999"
							>
								<div ref={suggestionsNode}>
									<Paper
										elevation={1}
										square
										{...options.containerProps}
										style={{ width: popperNode.current ? popperNode.current.clientWidth : null }}
									>
										{options.children}
										{state.noSuggestions && (
											<Typography style={{ fontSize : '15px', padding : '3px'}}>{props.noResults}</Typography>
										)}
									</Paper>
								</div>
							</Popper>
						)}
					/>
				</div>
			</div>
		</div>
	);


}

TitumSearch.propTypes = {};
TitumSearch.defaultProps = {
	trigger: (
		<IconButton className="w-40 h-40">
			<SearchIcon/>
		</IconButton>
	),
	placeholder: 'search products',
	noResults: 'No results..'
};

export default withRouter(React.memo(TitumSearch));
