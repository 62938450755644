import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Countdown from 'react-countdown';
import {connect, useDispatch, useSelector} from 'react-redux';
import { rendererThree } from '@titum/core/TitumCountDown';
import {addToCart} from "../../main/products/store/cartSlice";
import {serverAddToCart} from "../../main/products/store/serverCartSlice";
import TitumLoading from "@titum/core/TitumLoading";
import {setProductCategoryName} from "../../main/products/store/filterSlice";
import {addToCartToServer} from "../../../_constants/utilities.contants";
import {withRouter} from 'react-router-dom';
import {toast} from "react-toastify";

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

function ProductNine( props ) {
    const dispatch = useDispatch();
    const user = useSelector(({ auth }) => auth.user);
    const { product, addToCart, serverAddToCart, localCartList, isWishlist ,cartList} = props;


    const addToCartHandler = () => {
        if(product.is_display_size == 1) {
            props.history.push(`/product/${product.name.replace(/ /g, '-').toLowerCase()}/${product.sku}`)
        }else {
            if ( 0 !== product.quantity ) {
                const localCart = localCartList.filter((cat) => cat.id == product.id && cat.qty);
                const serverCart = cartList.filter((cat) => cat.id == product.id && cat.qty);

                let localQty = 0;
                let serverQty = 0;

                if(localCart.length !== 0) {
                    localQty =  localCart[0].qty + 1;
                }if(serverCart.length !== 0) {
                    serverQty =  serverCart[0].qty + 1;
                }

                if(user.role.length !== 0) {
                    if(serverQty <= product.quantity) {
                        serverAddToCart(product, 1);
                        addToCartToServer(cartList, product.id, product.unit_price, "", product.weight)
                    }else {
                        toast.success("Product quantity exceeded", {autoClose: 5000});
                    }
                }else{
                    if(localQty <= product.quantity) {
                        addToCart(product, 1);
                    }else {
                        toast.success("Product quantity exceeded", {autoClose: 5000});
                    }
                }
            }
        }

    }

    const wishlistHandler = () => {
        // if ( isWishlist ) {
        //     window.location = process.env.PUBLIC_URL + '/shop/wishlist';
        // } else {
        //     onToggleWishlist( product, isWishlist );
        // }
    }

    const quickViewHandler = () => {
        // showQuickView( product.id );
    }

    return (
        <>
            {product ? (
                <div className="product product-7 text-center">
                    <figure className="product-media">

                        { product.new ? <span className="product-label label-new">New</span> : '' }
                        { product.discount ? <span className="product-label label-sale">Sale</span> : '' }
                        { product.stock == 0 || product.quantity == 0 ? <span className="product-label label-out" style={{ backgroundColor : '#3399ff', color : 'white'}}>Out Of Stock</span> : '' }

                        <Link to={ `${process.env.PUBLIC_URL}/product/${product.name.replace(/ /g, '-').toLowerCase()}/${product.sku}` } onClick={ev => dispatch(setProductCategoryName(product.categories[0].name))}>


                            { product.images[0] ?
                                <>
                                    <LazyLoadImage
                                        alt="product"
                                        visibleByDefault={ true }
                                        src={ `${STORAGE_URL}/${product.images[0].url}` }
                                        threshold={ 100 }
                                    />

                                    <LazyLoadImage
                                        alt="product"
                                        visibleByDefault={ true }
                                        src={ `${STORAGE_URL}/${product.images[0].url}` }
                                        threshold={ 100 }
                                        wrapperClassName="product-image-hover product-image"
                                    />
                                </>
                                : ''
                            }

                            { product.images[1] ?
                                <span className="product-image-hover product-image">
                                <img
                                    alt="product"
                                    src={ `${STORAGE_URL}/${product.images[1].url}` }
                                />
                            </span>
                                : ''
                            }
                        </Link>

                        {
                            0 < product.discount ?
                                <div className="product-countdown countdown-compact">
                                    <Countdown date={ `2021-02-01T01:02:03` } renderer={ rendererThree } />
                                </div>
                                : ''
                        }

                        <div className="product-action-vertical">
                            <button
                                className={ `btn-product-icon btn-wishlist btn-expandable ${isWishlist ? 'added-to-wishlist' : 'remove-from-wishlist'}` }
                                onClick={ wishlistHandler }
                                title={ isWishlist ? "Go to wishlist" : "Add to wishlist" }
                            >
                                <span>{ isWishlist ? "go to wishlist" : "add to wishlist" }</span>
                            </button>

                            <button className="btn-product-icon btn-quickview" title="Quick view" onClick={ quickViewHandler }>
                                <span>Quick view</span>
                            </button>
                        </div>

                        <div className="product-action">
                            {product.stock && product.quantity != 0 ? (
                                <button className="btn-product btn-cart" onClick={ addToCartHandler }>
                                    <span>add to cart</span>
                                </button>
                            ):(
                                <button className="btn btn-light">
                                    <span>out of stock</span>
                                </button>
                            )}
                        </div>
                    </figure>

                    <div className="product-body">
                        <div className="product-cat">
                            { product.categories.map( ( cat, index ) => (
                                <span key={ `cat_${index}` } className="mr-0">
                                <Link to="#">{ cat.name }</Link>
                                    { index < product.categories.length - 1 ? ', ' : '' }
                            </span>
                            ) ) }
                        </div>

                        <h3 className="product-title">
                            <Link to={ `/product/${product.name.replace(/ /g, '-').toLowerCase()}/${product.sku}` } onClick={ev => dispatch(setProductCategoryName(product.categories[0].name))} >{ product.name }</Link>
                        </h3>

                        {
                            '0' === product.stock ?
                                <div className="product-price">
                                    <span className="out-price">Rs { product.unit_price.toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) }</span>
                                </div> :

                                0 < product.discount ?
                                    <div className="product-price">
                                        <span className="new-price">Rs { product.sale_price.toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) }</span>
                                        <span className="old-price">Was Rs { product.unit_price.toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) }</span>
                                    </div> :

                                    <div className="product-price">Rs { product.unit_price.toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) }</div>
                        }

                        <div className="ratings-container">
                            <div className="ratings">
                                <div className="ratings-val" style={ { width: product.ratings * 20 + '%' } }></div>
                            </div>
                            {/*<span className="ratings-text">({ product.reviews })</span>*/}
                        </div>

                    </div>
                </div>
            ):(<TitumLoading/>)}
        </>
    );
}

export const mapStateToProps = ( state, props ) => ({
    cartList: state.titumEcommerceApp.server.serverCartList,
    localCartList: state.titumEcommerceApp.cart.cartList,

})


export default withRouter(connect(mapStateToProps, { addToCart, serverAddToCart } )( ProductNine ));
