import React, {useEffect} from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import LoginTab from "./tabs/LoginTab";
import SignUpTab from "./tabs/SignUpTab";

function LoginModal( props ) {
	let timer;

	useEffect( () => {
		return () => {
			if ( timer ) clearTimeout( timer );
		}
	} )

	return (
		<div className="bg-light pt-3 pb-5">
			<div className="form-box my-4">
			<div className="form-tab">
				<Tabs selectedTabClassName="show" defaultIndex={ 0 }>
					<TabList className="nav nav-pills nav-fill">
						<Tab className="nav-item">
							<span className="nav-link">Sign In</span>
						</Tab>

						<Tab className="nav-item">
							<span className="nav-link">Register</span>
						</Tab>
					</TabList>

					<div className="tab-content">
						<TabPanel style={ { paddingTop: "2rem" } }>
							<LoginTab/>
						</TabPanel>

						<TabPanel>
							<SignUpTab/>
						</TabPanel>
					</div>
				</Tabs>
			</div>
		</div>
		</div>
	)
}

export default LoginModal;
