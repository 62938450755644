import { authRoles } from 'app/auth';
import VerifiedEmail from './VerifiedEmail';

const verifiedEmailConfig = {
    settings: {

    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: '/customer/verified/:message',
            component: VerifiedEmail
        }
    ]
};

export default verifiedEmailConfig;
