import React, { useEffect, useRef, useState } from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import { registerCustomer } from 'app/auth/store/registerSlice';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from "react-router-dom";
import {usePasswordValidation} from "../../../../_constants/usePasswordValidation";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

function SignUpTab() {

    const dispatch = useDispatch();
    const register = useSelector(({ auth }) => auth.register);
    const [showPassword, setShowPassword] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState({firstName : '', lastName: '', email : '', password : '', confirmPassword : ''})
    const pasValidation = usePasswordValidation(credentials.password,  credentials.confirmPassword, 10);
    const {validLength, hasNumber, upperCase, lowerCase, match, specialChar} = pasValidation;
    const [registered, setRegistered] = useState(false);

    let isValid = true;

    const signupFormRef = useRef(null);

    useEffect(() => {
        if (register.error) {
            setError(register.error);
            disableButton();
        }
    }, [register.error]);

    function disableButton() {
        setIsFormValid(false);
    }

    useEffect(() => {}, [credentials.password,  credentials.confirmPassword, validLength, hasNumber, upperCase, lowerCase, match, specialChar])



    function handleSubmit(event) {
        event.preventDefault();

        if(validatePassword()) {
            setIsFormValid(true);
            setLoading(true);
            dispatch(registerCustomer(credentials)).then((res) =>  {
                setLoading(false)
                setIsFormValid(false);
                if(res.payload === undefined) {
                    setError("");
                    setRegistered(true)
                }
            })
        }
    }


    const validatePassword = () => {
            if(validLength === '' && hasNumber === '' && upperCase === '' && lowerCase === '' && match === '' &&  specialChar === ''){
                isValid =true
            }else{
                isValid = false
            }
            return isValid;
    }


    return (
        <div className="w-full">
            {error && (
                <Alert 	className="mb-20" severity="error" style={{ fontSize: '12px', marginTop: '-20px'}}>{error}</Alert>
            )}
            {!registered ? (
                <div>
                    <form onSubmit={handleSubmit} ref={signupFormRef}>
                        <div className="form-group">
                            <label htmlFor="singin-email-2">First name *</label>
                            <input type="text"
                                   className="form-control"
                                   id="firstName"
                                   name="firstName"
                                   value={credentials.firstName}
                                   onChange={e => setCredentials({
                                       ...credentials,
                                       firstName: e.target.value
                                   })}
                                   required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="singin-email-2">Last name *</label>
                            <input type="text"
                                   className="form-control"
                                   id="lastName"
                                   name="lastName"
                                   value={credentials.lastName}
                                   onChange={e => setCredentials({
                                       ...credentials,
                                       lastName: e.target.value
                                   })}
                                   required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="singin-email-2">Email address *</label>
                            <input type="email"
                                   className="form-control"
                                   id="email"
                                   name="email"
                                   value={credentials.email}
                                   onChange={e => setCredentials({
                                       ...credentials,
                                       email: e.target.value
                                   })}
                                   required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="singin-password-2">Password *</label>
                            <input type={ showPassword ? 'text' : 'password'}
                                   className="form-control"
                                   id="login-password"
                                   name="login-password"
                                   value={credentials.password}
                                   onChange={e => setCredentials({
                                       ...credentials,
                                       password: e.target.value
                                   })}
                                   required
                            />

                            <div className='text-danger'>{validLength +' '+ lowerCase +' '+ upperCase +' '+  hasNumber+' '+ specialChar}</div>

                        </div>

                        <div className="form-group">
                            <label htmlFor="confirm-password-">Confirm Password *</label>
                            <input type={ showPassword ? 'text' : 'password'}
                                   className="form-control"
                                   id="confirm-password"
                                   name="confirm-password"
                                   value={credentials.confirmPassword}
                                   onChange={e => setCredentials({
                                       ...credentials,
                                       confirmPassword: e.target.value
                                   })}
                                   required
                            />
                            <div className='text-danger'>{credentials.password || credentials.confirmPassword ? match : ''}</div>

                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="signin-remember-2" />
                                <label className="custom-control-label" htmlFor="signin-remember-2" onClick={() => setShowPassword(!showPassword)}>Show password</label>
                            </div>

                        </div>

                        <div className="form-footer">
                            <button type="submit" className="btn btn-outline-primary-2" disabled={isFormValid}>
                                <span>SIGN UP</span>
                                {loading ? (<CircularProgress size={25} style={{ marginLeft : '10px'}}/>) : (<i className="icon-long-arrow-right"></i>)}

                            </button>

                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="register-policy-2" required />
                                <label className="custom-control-label" htmlFor="register-policy-2">I agree to the <Link to="#">privacy policy</Link> *</label>
                            </div>
                        </div>
                    </form >
                </div>
            ) : (
                <>

                    <Card >
                        <CardActionArea>
                            <Alert severity="success" style={{ fontSize : '15px'}}>A verification link has been sent to your email account!</Alert>
                            <CardContent>
                                <Typography variant="body2" color="textSecondary" component="p" style={{ fontSize : '12px'}}>
                                    Please click the link that has been just sent to you email account to verify you email
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </>
            ) }

        </div>
    );
}

export const mapStateToProps = ( state, props ) => ({
    cartList: state.titumEcommerceApp.cart.cartList,
})

export default connect(mapStateToProps, {} )( SignUpTab );


