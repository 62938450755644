import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;

export const getProduct = createAsyncThunk('titumEcommerceApp/product/getProduct', async (params, {dispatch}) => {
    try {
        const response = await axios.get(`${API_URL}/web/single-product`, {
            params,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        });

        const data = await response.data.product;
        if(data === null) {
            toast.warning("The product You are looking for, missing!" );
        }
        return data;
    }catch(error){
        toast.error("Something went wrong!" );
        throw error;
    }

});


const productSlice = createSlice({
    name: 'titumEcommerceApp/product',
    initialState: null,
    reducers: {
    },
    extraReducers: {
        [getProduct.fulfilled]: (state, action) => action.payload,
    }
});


export default productSlice.reducer;


