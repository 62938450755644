import { createSlice } from '@reduxjs/toolkit';
import { findIndex } from "@titum/utils/TitumUtils_2";
import storage from 'redux-persist/lib/storage'
import {persistReducer} from "redux-persist";


export const sortBy = (sortBy) => async (dispatch, getState) => {
    dispatch(setSortBy({sortBy}));
};

export const categoryFilter  = (productId) => async (dispatch, getState) => {
    dispatch(setCategoryFilter({productId}));
};

export const brandFilter  = (productId) => async (dispatch, getState) => {
    dispatch(setBrandFilter({productId}));
};

export const resetFilter = (productId, qty) => async (dispatch, getState) => {
    dispatch(setResetFilter({productId, qty}));
};

export const sizeFilter = (size) => async (dispatch, getState) => {
    dispatch(setSizeFilter({size}));
};

export const colorFilter = (shipping) => async (dispatch, getState) => {
    dispatch(setColorFilter({shipping}));

};

export const ratingFilter = (shipping) => async (dispatch, getState) => {
    dispatch(setRatingFilter({shipping}));

};

export const refreshStore = (shipping) => async (dispatch, getState) => {
    dispatch(setRefreshStore({shipping}));

};

export const priceFilter = (range) => async (dispatch, getState) => {
    dispatch(setPriceFilter({range}));

};


const initialState = {
    value: { min: 0, max: 10000 },
    sortBy: "popularity",
    category: [],
    size: [],
    color: [],
    brand: "",
    categoryName : 'Saree',
    brandName : 'MASTER FABRIC',
    rating: []
};

const filtersSlice = createSlice({
    name: 'titumEcommerceApp/filters',
    initialState,
    reducers: {
        setProductCategoryName: {
            reducer: (state, action) => {
                state.categoryName = action.payload;
            },
            prepare: value => ({ payload: value || '' })
        },
        setProductBrandName: {
            reducer: (state, action) => {
                state.brandName = action.payload;
            },
            prepare: value => ({ payload: value || '' })
        },
        setSortBy : {
            reducer: (state, action) => {
                return {
                    ...state,
                    sortBy: action.payload.sortBy
                };
            }
        },
        setCategoryFilter : {
            reducer: (state, action) => {
                let category = Object.assign( [], state.category );
                let index = findIndex( category, item => item === action.payload );

                if ( -1 === index ) {
                    category.push( action.payload );
                } else {
                    category.splice( index, 1 );
                }
                return {
                    ...state,
                    category: category
                };
            }
        },
        setResetFilter : {
            reducer: (state, action) => {
                return {
                    value: { min: 0, max: 10000 },
                    sortBy: "popularity",
                    categoryName : 'Saree',
                    brandName : 'MASTER FABRIC',
                    category: [],
                    size: [],
                    color: [],
                    brand: [],
                    rating: []
                };
            }
        },
        setSizeFilter : {
            reducer: (state, action) => {
                let size = Object.assign( [], state.size );
                let index = findIndex( size, item => item === action.payload.size );

                if ( -1 === index ) {
                    size.push( action.payload.size );
                } else {
                    size.splice( index, 1 );
                }
                return {
                    ...state,
                    size: size
                };
            }
        },
        setColorFilter : {
            reducer: (state, action) => {
                let color = Object.assign( [], state.color );
                let index = findIndex( color, item => item === action.payload.color );
                if ( -1 === index ) {
                    color.push( action.payload.color );
                } else {
                    color.splice( index, 1 );
                }
                return {
                    ...state,
                    color: color
                };
            }
        },
        setBrandFilter : {
            reducer: (state, action) => {
                return {
                    ...state,
                    brand: action.payload
                };
            }
        },
        setRatingFilter : {
            reducer: (state, action) => {
                let rating = Object.assign( [], state.rating );
                let index = findIndex( rating, item => item === action.payload.rating );
                if ( -1 === index ) {
                    rating.push( action.payload.rating );
                } else {
                    rating.splice( index, 1 );
                }
                return {
                    ...state,
                    rating: rating
                };
            }
        },
        setRefreshStore : {
            reducer: (state, action) => {
                return {
                    state: initialState
                };

            }
        },
        setPriceFilter : {
            reducer: (state, action) => {
                return {
                    ...state,
                    value: action.payload.range
                };
            }
        },
    }
});

const persistConfig = {
    keyPrefix: "weavers-",
    key: "filter",
    storage
}

export const {
    setSortBy, setCategoryFilter, setResetFilter,
    setSizeFilter, setColorFilter, setRatingFilter, setBrandFilter,
    setRefreshStore, setPriceFilter, setProductCategoryName, setProductBrandName } = filtersSlice.actions;

export default persistReducer( persistConfig, filtersSlice.reducer );
