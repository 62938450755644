import React from 'react';
import moment from 'moment';
import './style.css';
import {numberWithZero} from "../../../../../../_constants/utilities.contants";

export default function ViewOrder(props) {
    const {data, handleBack } = props;

    function getTotal(data) {
        let tot = 0;
        data.products.map(item => {
            tot += parseInt(item.unit_price * item.product_qty)
        })

        return tot;
    }

    function handleBackTo(){
        handleBack();
    }




    return (
        <>
            {data && (
                <>
                    <h6 className="mb-2" id="order-heading">Order <mark>#{data.order_code}</mark> was placed on <mark>{moment(data.date).format('ll')}</mark> and is currently {data.status.name}</h6>
                    <h1 className="card-title mb-1">Order details</h1>

                    <table id="normal-table-17">
                        <tr>
                            <th>Product</th>
                            <th>Total</th>
                        </tr>

                        {data.products.map((product) => (
                            <tr key={product.id}>
                                <td style={{textAlign: 'left', color : '#666666'}} className="product-title">
                                    <a href={`${process.env.PUBLIC_URL}/product/${product.name.replace(/ /g, '-').toLowerCase()}/${product.sku}`} target="_blank">
                                        {product.name} <span className="font-weight-bold"> &#10006;  {product.product_qty}</span>
                                    </a>
                                </td>
                                <td id="td-total">Rs {numberWithZero(product.unit_price * product.product_qty)}</td>
                            </tr>
                        ))}

                        <tr>
                            <th className="font-weight-bold cus-th">Subtotal:</th>
                            <td className="cus-td">Rs {numberWithZero(getTotal(data))}</td>
                        </tr>
                        <tr>
                            <th className="font-weight-bold cus-th">Shipping:</th>
                            <td className="cus-td">Free shipping</td>
                        </tr>
                        <tr>
                            <th className="font-weight-bold cus-th">Payment method:</th>
                            <td className="cus-td">Credit cart</td>
                        </tr>
                        <tr>
                            <th className="font-weight-bold cus-th">Total:</th>
                            <td className="cus-td">Rs {numberWithZero(getTotal(data))}</td>
                        </tr>
                    </table>

                    <div className="row mt-3">

                        <div className="col-lg-6">
                            <div className="card card-dashboard">
                                <div className="card-body">
                                    <h3 className="card-title">Billing Address</h3>
                                    {data.billing_addresses.length > 0 && (
                                        data.billing_addresses.map((item) => (
                                            <p>{item.first_name +' '+ item.last_name}<br />
                                                {item.address}<br />
                                                {item.province}<br />
                                                {item.city}<br />
                                                {item.postcode}<br/><br/>

                                                <i className="icon-phone"></i>{item.phone_no}<br />
                                                <i className="icon-envelope"></i>{item.email}<br />

                                            </p>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-6">
                            <div className="card card-dashboard">
                                <div className="card-body">
                                    <h3 className="card-title">Shipping Address</h3>

                                    {data.shipping_addresses.length > 0 && (
                                        data.shipping_addresses.map((item) => (
                                            <p>{item.first_name +' '+ item.last_name}<br />
                                                {item.address}<br />
                                                {item.province}<br />
                                                {item.city}<br />
                                                {item.postcode}<br />
                                             </p>
                                        ))
                                    )}
                                </div>
                            </div>
                            <button type="button" className="btn btn-primary" onClick={handleBackTo}>
                                <span>BACK</span>
                                <i className="icon-long-arrow-right"></i>
                            </button>
                        </div>
                    </div>

                </>
            )}
        </>
    );
}

