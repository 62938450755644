import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from "moment";

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: -42
    },
    billTo: {
        marginTop: 2,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
    flexContainer : {
        display: 'flex',
        flexDirection: 'row',
    },

    flexItemLeft : {
        padding: '10px',
        flexGrow : 1
    },

    flexItemCenter : {
        flexGrow : 3,
        paddingTop : '10px',
        paddingLeft : '15px',
    },
    flexItemRight : {
        paddingTop : '10px',
        paddingRight : '505px',
        flexGrow : 1,
    },
    invoiceContainer : {
        flexDirection: 'row',
    },
    invoiceIcon : {
        paddingLeft : '20px'
    }
});


const BillTo = ({invoice}) => {

    return (
        <View style={styles.flexContainer}>
            {invoice.billing_addresses.map((item) => (
                <View style={styles.flexItemLeft}>
                    <Text>{item.first_name + ' ' + item.last_name}</Text>
                    <Text>{item.address}</Text>
                    <Text>{item.city}</Text>
                    <Text>{item.province}</Text>
                    <Text>{item.post_code}</Text>
                    <Text>{item.phone_no}</Text>
                    <Text>{item.email}</Text>
                </View>
            ))}


            <View style={styles.flexItemCenter}>

                <View style={styles.invoiceContainer}>
                    <Text>Invoice Number </Text>
                    <Text style={{ paddingLeft : '15px'}}>:</Text>
                </View >
                <View style={styles.invoiceContainer}>
                    <Text>Invoice Date </Text>
                    <Text style={{ paddingLeft : '28px'}}>:</Text>
                </View >
                <View style={styles.invoiceContainer}>
                    <Text>Payment Method </Text>
                    <Text style={{ paddingLeft : '3px'}}>:</Text>
                </View>
                <View style={styles.invoiceContainer}>
                    <Text>Order Number </Text>
                    <Text style={{ paddingLeft : '15px'}}>:</Text>
                </View>
                <View style={styles.invoiceContainer}>
                    <Text>Order Date </Text>
                    <Text style={{ paddingLeft : '25px'}}>:</Text>
                </View>
            </View>


            <View style={styles.flexItemRight}>
                {invoice.invoices.map((item) => (
                    <>
                        <View>
                            <Text>#{item.invoice_code}</Text>
                        </View >
                        <View>
                            <Text>{moment(item.date).format('ll')}</Text>
                        </View >
                        <View>
                            <Text>{invoice.payment_method}</Text>
                        </View >
                    </>

                ))}
                <View>
                    <Text>#{invoice.order_code}</Text>
                </View >
                <View>
                    <Text>{moment(invoice.date).format('ll')}</Text>
                </View >
            </View>
        </View>

    );

};

export default BillTo