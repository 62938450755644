import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import LocalCartMenu from "../components/cart-menu/LocalCartMenu";
import ServerCartMenu from "../components/cart-menu/ServerCartMenu";

function CartMenu( props ) {
    const user = useSelector(({ auth }) => auth.user);

    useEffect(() => {}, [user])

    return (
        <>
            {
                user.role.length === 0 ? (<LocalCartMenu/>):(<ServerCartMenu/>)
            }
        </>
    );
}

export default CartMenu;
